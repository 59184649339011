import { EntityAdapter, Update } from "@reduxjs/toolkit";
import { aeTitleRuleAdapter, backendValidationErrorAdapter, customizationBaseAdapter, dicomAttributeRuleAdapter, dicomRuleAdapter, doseOutputAdapter, doseRoiAdapter, DoseSliceState, doseTargetAdapter, formValidationErrorAdapter, modelAdapter, outputMetadataAdapter } from "../../dose/doseSlice";
import GenericSliceWrapper, { FormValidationErrorCalculationResults, RemovedOutputEntities } from "./genericSliceWrapper";
import { DoseCustomizationEntities, DoseCustomizationOutput, DoseRoi, DoseTarget } from "../../dose/dose-types";
import { CustomizationObjectType } from "../customization-types";
import { FormValidationErrorType } from "../form-errors";
import { FormValidationError, generateNewFormValidationError } from "../store-errors";

export class DoseSliceWrapper extends GenericSliceWrapper<DoseSliceState> {

    outputAdapter: EntityAdapter<DoseCustomizationOutput, string>;
    roiAdapter: EntityAdapter<DoseRoi, string>;
    targetAdapter: EntityAdapter<DoseTarget, string>;

    constructor() {
        super(
            modelAdapter,
            customizationBaseAdapter,
            outputMetadataAdapter,
            aeTitleRuleAdapter,
            dicomRuleAdapter,
            dicomAttributeRuleAdapter,
            backendValidationErrorAdapter,
            formValidationErrorAdapter
        )
        this.outputAdapter = doseOutputAdapter;
        this.roiAdapter = doseRoiAdapter;
        this.targetAdapter = doseTargetAdapter;
    }

    protected findOutput(state: DoseSliceState, outputId: string): DoseCustomizationOutput | undefined {
        return state.doseOutputs.entities[outputId];
    }

    protected updateOutput(state: DoseSliceState, update: Update<DoseCustomizationOutput, string>): void {
        this.outputAdapter.updateOne(state.doseOutputs, update);
    }

    /** Collects which store entities need to be removed and updated when a customization output is removed. */
    public collectCustomizationOutputEntitiesFromStoreForRemoval(state: DoseSliceState, output: DoseCustomizationOutput, customizationBaseId: string): RemovedOutputEntities {

        const roiIdsToBeRemoved: string[] = [];
        const targetIdsToBeRemoved: string[] = [];
        const metadataIdsToBeRemoved: string[] = [];

        metadataIdsToBeRemoved.push(...output.metadata);
        roiIdsToBeRemoved.push(...output.rois);
        targetIdsToBeRemoved.push(...output.targets);

        return {
            roiIdsToBeRemoved,
            targetIdsToBeRemoved,
            metadataIdsToBeRemoved,
            globalRoiChanges: [],
        };
    };



    protected removeOutputRelatedItems(state: DoseSliceState, outputIds: string[], items: RemovedOutputEntities) {
        const { roiIdsToBeRemoved, metadataIdsToBeRemoved, targetIdsToBeRemoved } = items;


        this.roiAdapter.removeMany(state.doseRois, roiIdsToBeRemoved);
        this.targetAdapter.removeMany(state.doseTargets, targetIdsToBeRemoved);
        this.outputMetadataAdapter.removeMany(state.outputMetadata, metadataIdsToBeRemoved);
        this.outputAdapter.removeMany(state.doseOutputs, outputIds);
    }

    protected setOutputRelatedItemsAsModified(state: DoseSliceState, entitiesToUpdate: Partial<DoseCustomizationEntities>) {
        const roiIds = entitiesToUpdate.doseRois?.map(id => id.id);
        const targetIds = entitiesToUpdate.doseTargets?.map(id => id.id);
        const customizationOutputsIds = entitiesToUpdate.doseOutputs?.map(id => id.id);

        if (roiIds && roiIds.length > 0) {
            this.roiAdapter.updateMany(
                state.doseRois,
                roiIds.map(roiId => ({ id: roiId, changes: { isModified: true } }))
            );
        }

        if (targetIds && targetIds.length > 0) {
            this.targetAdapter.updateMany(
                state.doseTargets,
                targetIds.map(targetId => ({ id: targetId, changes: { isModified: true } }))
            );
        }
        
        if (customizationOutputsIds && customizationOutputsIds.length > 0) {
            this.outputAdapter.updateMany(
                state.doseOutputs,
                customizationOutputsIds.map(customizationOutputId => ({ id: customizationOutputId, changes: { isModified: true } }))
            );
        }
    }

    /** Calculates form validation errors for given customization output.
     * TODO: once we have more types of validation errors consider splitting
     * this into smaller functions, and consider whether all of them need to be
     * checked here.
     */
    protected calculateFormValidationErrorsForCustomizationOutput(state: DoseSliceState, outputId: string): FormValidationErrorCalculationResults {
        const errorIdsToRemove: string[] = [];
        const errorsToAdd: FormValidationError[] = [];

        const output = state.doseOutputs.entities[outputId];
        if (!output) { throw new Error(`Customization output (${outputId}) is undefined`); }
        const rois = output.rois.map(rId => state.doseRois.entities[rId]);

        const existingErrors = Object.values(state.formValidationErrors.entities).filter(e => output.rois.includes(e?.itemId));
        const existingEmptyNameErrors = existingErrors.filter(e => e?.errorType === FormValidationErrorType.RoiNameIsEmpty);
        const existingDuplicateNameErrors = existingErrors.filter(e => e?.errorType === FormValidationErrorType.DuplicateRoiNamesInOutput);

        // collect existing duplicate name errors nearby so we can use them when doing a delta within this output
        // map potential INCLUDED duplicate roiIds (string array value) under the potential duplicate name (string key)
        // this is needed for the duplicate name check resolution later
        const duplicateNames: { [name: string]: string[] } = {};
        for (const roi of rois) {
            if (roi.isIncluded) {
                duplicateNames[roi.name] = duplicateNames[roi.name] || [];
                duplicateNames[roi.name].push(roi.id);
            }
        }

        // check all the validation errors for ROIs in one loop
        for (const roi of rois) {

            let dontAddNewValidationErrors = false;


            // check for empty names rois (RoiNameIsEmpty)
            const matchingExistingEmptyNameErrors = existingEmptyNameErrors.filter(e => e.itemId === roi.id);
            const hasMatchingEmptyNameErrors = matchingExistingEmptyNameErrors.length > 0;
            const isNameEmpty = !roi.name.trim();

            // NOTE: new errors are NOT created if existing ones with the same FormValidationErrorType have already been found!
            if (isNameEmpty && !hasMatchingEmptyNameErrors && !dontAddNewValidationErrors) {
                // create a new error
                errorsToAdd.push(generateNewFormValidationError(FormValidationErrorType.RoiNameIsEmpty, roi.id, CustomizationObjectType.Roi));
            } else if (dontAddNewValidationErrors || (!isNameEmpty && hasMatchingEmptyNameErrors)) {
                // remove existing errors
                errorIdsToRemove.push(...matchingExistingEmptyNameErrors.map(m => m.id));
            }

            // if this name was empty then don't add any new validation errors (but we still need to check if any can be removed)
            if (isNameEmpty) {
                dontAddNewValidationErrors = true;
            }


            // check for duplicate names within (included) rois in the same output (DuplicateRoiNamesInOutput)
            // now loop through the ROIs again and do a delta against existing errors, creating new errors and marking existing ones to be removed as needed
            const matchingExistingDuplicateNameErrors = existingDuplicateNameErrors.filter(e => e.itemId === roi.id);
            const hasMatchingDuplicateNameErrors = matchingExistingDuplicateNameErrors.length > 0;
            const isNameDuplicated = duplicateNames[roi.name] && duplicateNames[roi.name].length > 1;

            // NOTE: new errors are NOT created if existing ones with the same FormValidationErrorType have already been found!
            if (isNameDuplicated && !hasMatchingDuplicateNameErrors && !dontAddNewValidationErrors) {
                // create a new error
                errorsToAdd.push(generateNewFormValidationError(FormValidationErrorType.DuplicateRoiNamesInOutput, roi.id, CustomizationObjectType.Roi));
            } else if (dontAddNewValidationErrors || (!isNameDuplicated && hasMatchingDuplicateNameErrors)) {
                // remove existing errors
                errorIdsToRemove.push(...matchingExistingDuplicateNameErrors.map(m => m.id));
            }
        }

        return {
            validationErrorIdsToRemove: errorIdsToRemove,
            validationErrorsToAdd: errorsToAdd,
        }
    }
}
