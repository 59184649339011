import { Stack, StackItem, Level, LevelItem, Toolbar, ToolbarContent, ToolbarItem, Button, Modal, ModalVariant, FormGroup, Divider, DropdownItem, Dropdown, MenuToggle, MenuToggleElement } from '@patternfly/react-core';
import React, { useCallback, useEffect, useState } from 'react';
import { EllipsisVIcon, PlusIcon } from '@patternfly/react-icons';
import { useTranslation } from 'react-i18next';

import ModelSelectionRuleWrapper from './SelectionRuleWrapper';
import { ModelSelectionUndoState } from './ModelSelectionPage';
import { getFormValidationErrorMessage } from '../../store/global-types/form-errors';
import { defaultValidation, FormValidationState } from '../../components/form-validation-state';
import { CustomizationObjectType, DicomRule, ModelSelectionScope, ModelType } from '../../store/global-types/customization-types';
import { BackendValidationErrorViewModel, FormValidationError } from '../../store/global-types/store-errors';
import ValidationHelperText from '../../components/validation-helper-text';


interface DicomRuleFormProps {
    modelType: ModelType,
    dicomRule: DicomRule,
    validationError: BackendValidationErrorViewModel | undefined,
    formValidationErrors: FormValidationError[],
    runningNumber: number,
    setUndoState: (undoState: ModelSelectionUndoState) => void,
    isUndoDisabled?: boolean,
    onCreateNewDicomAttributeRule: () => void,
    onDicomRuleRemoved: () => void,
    getDicomAttributeRule: (id: string, runningNumber: number, onRemoveDicomRuleButtonClicked: () => void) => JSX.Element,
}

const DicomRuleForm = (props: DicomRuleFormProps) => {
    const { modelType, dicomRule, validationError, formValidationErrors,
        runningNumber, setUndoState, isUndoDisabled,
        onCreateNewDicomAttributeRule, onDicomRuleRemoved, getDicomAttributeRule } = props;

    const { t } = useTranslation();

    const [isActionListOpen, setActionListOpenState] = useState(false);
    const [isRemoveDicomRuleModalOpen, setRemoveDicomRuleModalOpenState] = useState(false);
    const [formValidation, setFormValidation] = useState<FormValidationState>(defaultValidation);

    /** Set non-blocking form validation errors generated in redux store reducers to be visible in this form's normal UI validation. */
    useEffect(() => {
        if (formValidationErrors.length > 0) {
            const allErrorsAsMessage = formValidationErrors.map(e => getFormValidationErrorMessage(e.errorType)).join(' ');
            setFormValidation({ validated: 'error', helperTextInvalid: allErrorsAsMessage });
        } else {
            setFormValidation(defaultValidation);
        }
    }, [formValidationErrors, dicomRule]);

    const addDicomAttribute = useCallback(() => {
        onCreateNewDicomAttributeRule();
    }, [onCreateNewDicomAttributeRule]);

    const handleRemoveDicomRuleButtonClick = useCallback(() => {
        setActionListOpenState(false);
        setRemoveDicomRuleModalOpenState(true);
    }, []);

    const handleRemoveDicomRuleConfimation = useCallback(() => {
        setRemoveDicomRuleModalOpenState(false);
        onDicomRuleRemoved();
    }, [onDicomRuleRemoved]);

    const handleUndo = useCallback(() => {
        setUndoState({ isModalOpen: true, scope: ModelSelectionScope.DicomRule, id: dicomRule.id });
        setActionListOpenState(false); // close the action list
    }, [dicomRule, setUndoState]);

    const dropdownItems = [<DropdownItem key="removeDicomRule" onClick={handleRemoveDicomRuleButtonClick}>{t('selectionPage.removeDicomRule')}</DropdownItem>];
    if (!isUndoDisabled) {
        dropdownItems.unshift(<DropdownItem key="undoDicomRuleChanges" isDisabled={!dicomRule.isModified || dicomRule.isNew}
            onClick={handleUndo}>{t('selectionPage.undoDicomRuleChanges')}</DropdownItem>);
    }

    const hasValidationError = validationError !== undefined && validationError.type === CustomizationObjectType.DicomRule;
    const formValidationState = dicomRule.isValid ? 'default' : 'error';

    return (
        <ModelSelectionRuleWrapper
            key={dicomRule.id}
            title={t('selectionPage.dicomRuleNumber', { number: runningNumber })}
            isModified={dicomRule.isModified}
            className="dicom-rule"
            hasValidationError={hasValidationError}
            validationError={validationError}>

            <Stack>
                <FormGroup>
                    {dicomRule.dicomAttributes.map((dicomAttributeId, i) => (getDicomAttributeRule(dicomAttributeId, i + 1, handleRemoveDicomRuleButtonClick)))}

                    <StackItem className="model-selection-toolbar dicom-rule-toolbar">
                        <Level>
                            <LevelItem>
                                <Toolbar>
                                    <ToolbarContent>
                                        <ToolbarItem>
                                            <Button
                                                size="sm"
                                                variant="secondary"
                                                icon={<PlusIcon />}
                                                // isDisabled={isToolbarDisabled}
                                                onClick={addDicomAttribute}>{t('selectionPage.addDicomAttribute')}</Button>
                                        </ToolbarItem>

                                    </ToolbarContent>
                                </Toolbar>
                            </LevelItem>
                            <LevelItem>
                                <Dropdown
                                    toggle={(toggleRef: React.Ref<MenuToggleElement>) => <MenuToggle ref={toggleRef} onClick={() => {setActionListOpenState(!isActionListOpen)}} variant='plain'><EllipsisVIcon/></MenuToggle>}
                                    isPlain
                                    shouldFocusFirstItemOnOpen={false}
                                    popperProps={{position:'right'}}
                                    isOpen={isActionListOpen}
                                    onOpenChange={() => setActionListOpenState(!isActionListOpen)}>{dropdownItems}</Dropdown>
                            </LevelItem>
                        </Level>
                    </StackItem>
                    {formValidationState !== 'default' && (
                        <ValidationHelperText
                            validated={formValidationState}
                            helperText={formValidation.helperTextInvalid}
                        />
                    )}
                    <Modal
                        variant={ModalVariant.small}
                        title={t('selectionPage.removeDicomRule.confirm')}
                        isOpen={isRemoveDicomRuleModalOpen}
                        onClose={() => setRemoveDicomRuleModalOpenState(false)}
                        actions={[
                            <Button key="confirmRemoval" variant="danger" onClick={handleRemoveDicomRuleConfimation}>{t('selectionPage.removeDicomRule.short')}</Button>,
                            <Button key="cancel" variant="tertiary" onClick={() => setRemoveDicomRuleModalOpenState(false)}>{t('common.cancel')}</Button>
                        ]}
                    >
                        Do you want to remove this entire DICOM rule? Note that your changes won't be saved until you click on "Save".
                    </Modal>
                </FormGroup>
            </Stack>
            <Divider />
        </ModelSelectionRuleWrapper >
    );
}

export default DicomRuleForm;
