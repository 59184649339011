import { EmptyState, EmptyStateBody, PageSection, PageSectionVariants, EmptyStateHeader, EmptyStateFooter,  } from '@patternfly/react-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import logo70b from '../../img/mvision-title.png';
import { appConfigSelectors } from '../../store/appConfig/appConfigSlice';
import { DeploymentMode } from '../../store/appConfig/appDeploymentInfoTypes';
import { configurationTargetSelectors } from '../../store/configurationTarget/configurationTargetSlice';


const HomePage = () => {

    const { t } = useTranslation();
    const clientList = useSelector(configurationTargetSelectors.selectConfigurationTargetList);
    const deploymentInfo = useSelector(appConfigSelectors.selectAppDeploymentInfo);

    return (
        <>
            <PageSection variant={PageSectionVariants.light}>
                <EmptyState>
                    {/* Weird bug in cockpit: returning to the home page will fail to load the logo image the second time! So don't show it in the first place. */}
                    {deploymentInfo && deploymentInfo.deploymentMode !== DeploymentMode.Cockpit && (
                        <div className="logged-out-logo">
                            <img src={logo70b} alt="MVision logo" />
                        </div>
                    )}

                    <EmptyStateHeader titleText={<>{t('homePage.title.long', { appName: t('appTitle') })}</>} headingLevel="h4" color="white" /><EmptyStateFooter>
                    {clientList && (<EmptyStateBody>{t(clientList.length > 1 ? 'homePage.body.multipleTargets' : 'homePage.body.oneTarget', { appName: t('appTitle') })}</EmptyStateBody>)}
                </EmptyStateFooter></EmptyState>
            </PageSection>
        </>
    );
}

export default HomePage;
