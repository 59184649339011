import { DataList, FormGroup, InputGroup, Switch, Radio } from '@patternfly/react-core';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../../store/store';
import { doseScalingDoseSet, doseScalingIsActiveSet, doseScalingMethodSet, doseScalingVolumeSet, doseSelectors, outputIsRTPlanIncludedSet, outputMachineNameSet, outputMachineTypeSet, pixelSpacingSet, zDoseCroppingIsActiveSet, zDoseCroppingValueSet } from '../../../store/dose/doseSlice';
import DoseOutputSection from './DoseOutputSection';
import DoseOutputSectionItem from './DoseOutputSectionItem';
import { DoseScalingMethod } from '../../../store/dose/dose-types';
import NumberInput from '../../../components/number-input';
import MachineTypeSelect from './MachineTypeSelect';
import TextOverrideField from '../../../components/text-override-field';

interface DoseOutputFormProps {
    outputId: string,
}

const DoseOutputForm = (props: DoseOutputFormProps) => {

    const { outputId } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const output = useSelector((state: StoreState) => doseSelectors.selectOutputById(state, outputId));

    const handleDoseScalingToggled = useCallback(() => {
        if (!output) { throw new Error('Dose output is undefined'); }
        dispatch(doseScalingIsActiveSet({ id: outputId, isActive: !output.doseScaling.isActive }));
    }, [dispatch, output]);

    const handleSetDoseScalingMethodToVolume = useCallback(() => {
        dispatch(doseScalingMethodSet({ id: outputId, method: DoseScalingMethod.Volume }));
    }, [dispatch]);

    const handleSetDoseScalingMethodToMean = useCallback(() => {
        dispatch(doseScalingMethodSet({ id: outputId, method: DoseScalingMethod.Mean }));
    }, [dispatch]);

    const handleDoseScalingVolumeChanged = useCallback((value: number) => {
        dispatch(doseScalingVolumeSet({ id: outputId, volume: value }));
    }, [dispatch]);

    const handleDoseScalingMinDoseChanged = useCallback((value: number) => {
        dispatch(doseScalingDoseSet({ id: outputId, dose: value }));
    }, [dispatch]);

    const handlePixelSpacingChanged = useCallback((value: number) => {
        dispatch(pixelSpacingSet({ id: outputId, resolution: value }));
    }, [dispatch]);

    const handleZDoseCroppingToggled = useCallback(() => {
        if (!output) { throw new Error('Dose output is undefined'); }
        dispatch(zDoseCroppingIsActiveSet({ id: outputId, isActive: !output.zDoseCropping.isActive }));
    }, [dispatch, output]);

    const handleZDoseCroppingValueChanged = useCallback((value: number) => {
        if (!output) { throw new Error('Dose output is undefined'); }
        dispatch(zDoseCroppingValueSet({ id: outputId, value: value }));
    }, [dispatch, output]);

    const handleIsRTPlanIncludedToggled = useCallback(() => {
        if (!output) { throw new Error('Dose output is undefined'); }
        dispatch(outputIsRTPlanIncludedSet({ id: outputId, isIncluded: !output.isRTPlanIncluded }));
    }, [dispatch, output]);

    const handleMachineTypeChanged = useCallback((value: string) => {
        if (!output) { throw new Error('Dose output is undefined'); }
        dispatch(outputMachineTypeSet({ id: outputId, machineType: value }));
    }, [dispatch]);

    const handleMachineNameChanged = useCallback((value: string) => {
        if (!output) { throw new Error('Dose output is undefined'); }
        dispatch(outputMachineNameSet({ id: outputId, machineName: value }));
    }, [dispatch]);

    // TODO: add section-specific output validity checking here later on if we have
    // any relevant form validation for those parts -- currently we don't
    const outputIsValid = true;

    if (!output) {
        return null;
    }

    return (
        <DataList aria-label={`Dose file customization`} isCompact className="dose-output-section">

            <DoseOutputSection
                title={t('customizationPage.dose.outputSection.doseScaling')}
                isModified={false}
                hasValidationError={!outputIsValid}
                validationError={undefined}
            >
                <DoseOutputSectionItem label={t('customizationPage.dose.outputSection.doseScaling.isActive.label')}>
                    <FormGroup
                    >
                        <InputGroup>
                            <Switch
                                label={t('customizationPage.dose.outputSection.doseScaling.isActive.on')}
                                labelOff={t('customizationPage.dose.outputSection.doseScaling.isActive.off')}
                                isChecked={output.doseScaling.isActive}
                                onChange={handleDoseScalingToggled}
                            />
                        </InputGroup>
                    </FormGroup>
                </DoseOutputSectionItem>

                <DoseOutputSectionItem label={t('customizationPage.dose.outputSection.doseScaling.method.label')}>
                    <FormGroup
                        role="radiogroup"
                        isStack
                    >
                        <Radio
                            name="dose-output-dosescaling-method"
                            id="dose-output-dosescaling-method-volume"
                            label={t('customizationPage.dose.outputSection.doseScaling.method.enum.Volume')}
                            onChange={handleSetDoseScalingMethodToVolume}
                            isChecked={output.doseScaling.method === DoseScalingMethod.Volume}
                        />
                        <Radio
                            name="dose-output-dosescaling-method"
                            id="dose-output-dosescaling-method-mean"
                            label={t('customizationPage.dose.outputSection.doseScaling.method.enum.Mean')}
                            onChange={handleSetDoseScalingMethodToMean}
                            isChecked={output.doseScaling.method === DoseScalingMethod.Mean}
                        />
                    </FormGroup>
                </DoseOutputSectionItem>

                <DoseOutputSectionItem label={t('customizationPage.dose.outputSection.doseScaling.volume.label')}>
                    <FormGroup>
                        <NumberInput
                            fieldId={`dose-scaling-volume-field-for-${outputId}`}
                            onChange={handleDoseScalingVolumeChanged}
                            defaultValue={output.doseScaling.volume}
                        />
                    </FormGroup>
                </DoseOutputSectionItem>

                <DoseOutputSectionItem label={t('customizationPage.dose.outputSection.doseScaling.dose.label')}>
                    <FormGroup>
                        <NumberInput
                            fieldId={`dose-scaling-dose-field-for-${outputId}`}
                            onChange={handleDoseScalingMinDoseChanged}
                            defaultValue={output.doseScaling.dose}
                        />
                    </FormGroup>
                </DoseOutputSectionItem>
            </DoseOutputSection>

            <DoseOutputSection
                title={t('customizationPage.dose.outputSection.pixelSpacing')}
                isModified={false}
                hasValidationError={!outputIsValid}
                validationError={undefined}
            >
                <DoseOutputSectionItem label={t('customizationPage.dose.outputSection.pixelSpacing.resolution')}>
                    <FormGroup>
                        <NumberInput
                            fieldId={`pixel-spacing-field-for-${outputId}`}
                            onChange={handlePixelSpacingChanged}
                            defaultValue={output.pixelSpacing ? output.pixelSpacing[0] : undefined}
                        />
                    </FormGroup>
                </DoseOutputSectionItem>
            </DoseOutputSection>

            <DoseOutputSection
                title={t('customizationPage.dose.outputSection.zDoseCropping')}
                isModified={false}
                hasValidationError={!outputIsValid}
                validationError={undefined}
            >
                <DoseOutputSectionItem label={t('customizationPage.dose.outputSection.zDoseCropping.isActive.label')}>
                    <FormGroup
                    >
                        <InputGroup>
                            <Switch
                                label={t('customizationPage.dose.outputSection.zDoseCropping.isActive.on')}
                                labelOff={t('customizationPage.dose.outputSection.zDoseCropping.isActive.off')}
                                isChecked={output.zDoseCropping.isActive}
                                onChange={handleZDoseCroppingToggled}
                            />
                        </InputGroup>
                    </FormGroup>
                </DoseOutputSectionItem>

                <DoseOutputSectionItem label={t('customizationPage.dose.outputSection.zDoseCropping.value.label')}>
                    <FormGroup>
                        <NumberInput
                            fieldId={`zdose-cropping-field-for-${outputId}`}
                            onChange={handleZDoseCroppingValueChanged}
                            defaultValue={output.zDoseCropping.value}
                        />
                    </FormGroup>
                </DoseOutputSectionItem>
            </DoseOutputSection>

            <DoseOutputSection
                title={t('customizationPage.dose.outputSection.generic')}
                isModified={false}
                hasValidationError={!outputIsValid}
                validationError={undefined}
            >
                <DoseOutputSectionItem label={t('customizationPage.dose.outputSection.isRTPlanIncluded')}>
                    <FormGroup>
                        <InputGroup>
                            <Switch
                                label={t('customizationPage.dose.outputSection.isRTPlanIncluded.on')}
                                labelOff={t('customizationPage.dose.outputSection.isRTPlanIncluded.off')}
                                isChecked={output.isRTPlanIncluded}
                                onChange={handleIsRTPlanIncludedToggled}
                            />
                        </InputGroup>
                    </FormGroup>
                </DoseOutputSectionItem>

                <DoseOutputSectionItem label={t('customizationPage.dose.outputSection.machineType')}>
                    <FormGroup>
                        <MachineTypeSelect
                            value={output.machineType}
                            onSelect={handleMachineTypeChanged}
                        />
                    </FormGroup>
                </DoseOutputSectionItem>

                <DoseOutputSectionItem label={t('customizationPage.dose.outputSection.machineName')}>
                    <FormGroup>
                        <TextOverrideField
                            defaultText={output.machineName}
                            textValue={output.machineName}
                            id={`machine-name-for-${output.id}`}
                            onChange={handleMachineNameChanged}
                            allowEmptyField={true}
                        />
                        {/* {nameFieldValidation.validated !== 'default' && (
                            <ValidationHelperText
                                validated={nameFieldValidation.validated}
                                helperText={nameFieldValidation.helperTextInvalid}
                            />
                        )} */}
                    </FormGroup>
                </DoseOutputSectionItem>
            </DoseOutputSection>

        </DataList>
    );
}

export default DoseOutputForm;