import React, { useCallback, useEffect, useState } from 'react';
import DebouncedTextInput from './debounced-text-input';

interface NumberInputProps {
    fieldId: string,
    defaultValue: number | undefined,
    onChange: (value: number) => void,
}

// TODO: make this component more robust when the input contains non-valid characters
// (including when the user is in progress of entering a valid number, e.g. they've
// typed in "0." with the decimals still missing)
const NumberInput = (props: NumberInputProps) => {
    const { fieldId, defaultValue, onChange } = props;

    // const [defaultValue, setDefaultValue] = useState('');
    const [validationState, setValidationState] = useState<'success' | 'warning' | 'error' | 'default'>('default');

    // useEffect(() => {
    //     setDefaultValue(props.defaultValue.toString());
    // }, [props.defaultValue]);

    const handleChange = useCallback((value: string) => {
        const valueAsNumber = parseFloat(value);
        if (!isNaN(valueAsNumber)) {
            setValidationState('default');
            onChange(valueAsNumber);
        } else {
            setValidationState('error');
        }
    }, [onChange]);

    return (
        <DebouncedTextInput
            fieldId={fieldId}
            defaultValue={defaultValue?.toString() || ''}
            onDebouncedChange={handleChange}
            type="text"
            validated={validationState}
        />
    );
}

export default NumberInput;