import React, { useCallback } from 'react';
import { Table, Tbody, Th, Thead, Tr } from '@patternfly/react-table';
import { ResourceKey } from 'i18next';
import { useTranslation } from 'react-i18next';
import { PlusIcon } from '@patternfly/react-icons';
import { Button, Level, LevelItem, Toolbar, ToolbarContent, ToolbarItem } from '@patternfly/react-core';
import { useDispatch } from 'react-redux';

import DoseTargetForm from './DoseTargetForm';
import { newDoseTargetAdded } from '../../../store/dose/doseSlice';

/** Ids for different types of dose target table columns. */
export const columnIds = {
    method: 'Method',
    unit: 'Unit',
    matchPattern: 'Match pattern',
    prescription: 'Prescription',
};

/** Localization keys for dose target table column headers. */
export const columnLangKeys: { [columnId: string]: ResourceKey } = {
    [columnIds.method]: 'column.method',
    [columnIds.unit]: 'column.unit',
    [columnIds.matchPattern]: 'column.matchPattern',
    [columnIds.prescription]: 'column.fixedPrescription',
}

/** Styling names for dose target table columns. */
export const columnCssNames = {
    modified: 'column-modified',
    method: 'column-method',
    unit: 'column-unit',
    matchPattern: 'column-match-pattern',
    prescription: 'column-prescription',
}

export const columnTooltips = {
    [columnIds.method]: (<span>Method of finding the target. Either "variable" for getting the prescription dose from the ROI name, or "fixed" for a fixed dose if the regular expression is matched.</span>),
    [columnIds.unit]: (<span>Unit of radiation used in this dose target.</span>),
    [columnIds.matchPattern]: (<span>Regular expression to find target ROIs, optionally including a method to extract the PTV prescription.</span>),
}

export type DoseTargetTableColumnKey = keyof typeof columnIds;



interface DoseTargetTableProps {
    outputId: string;
    targetIds: string[];
}

const DoseTargetTable = (props: DoseTargetTableProps) => {

    const { outputId, targetIds } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleAddDoseTarget = useCallback(() => {
        dispatch(newDoseTargetAdded(outputId));
    }, [dispatch, outputId]);

    return (
        <div>
            <div>
                <Table variant='compact' isStickyHeader className="mv-sticky-fix">
                    <Thead>
                        <Tr className='customization-form-table'>
                            <Th aria-label='Is modified?'></Th>
                            <Th aria-label='Method' info={{ tooltip: columnTooltips[columnIds.method] }}>{t(columnLangKeys[columnIds.method])}</Th>
                            <Th aria-label='Unit' info={{ tooltip: columnTooltips[columnIds.unit] }}>{t(columnLangKeys[columnIds.unit])}</Th>
                            <Th aria-label='Match pattern' info={{ tooltip: columnTooltips[columnIds.matchPattern] }}>{t(columnLangKeys[columnIds.matchPattern])}</Th>
                            <Th aria-label='Fixed prescription'>{t(columnLangKeys[columnIds.prescription])}</Th>
                            <Th aria-label='Action menu'></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {targetIds.map(tId => <DoseTargetForm targetId={tId} key={tId} targetIdList={targetIds} />)}
                    </Tbody>
                </Table>
            </div>

            <div>
                <Level>
                    <LevelItem>
                        <Toolbar>
                            <ToolbarContent>
                                <ToolbarItem>
                                    <Button
                                        size="sm"
                                        variant="secondary"
                                        icon={<PlusIcon />}
                                        // isDisabled={isToolbarDisabled}
                                        onClick={handleAddDoseTarget}>{t('customizationPage.dose.rois.addNewDoseTarget')}</Button>
                                </ToolbarItem>

                            </ToolbarContent>
                        </Toolbar>
                    </LevelItem>
                </Level>
            </div>

        </div>
    );
}

export default DoseTargetTable;
