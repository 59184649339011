import React, { PropsWithChildren } from 'react';
import { Split, SplitItem, StackItem, TextContent, Text } from '@patternfly/react-core';

interface DoseOutputSectionItemProps {
    label: string,
}

const DoseOutputSectionItem = (props: PropsWithChildren<DoseOutputSectionItemProps>) => {
    const { label, children } = props;

    return (
        <StackItem className="dose-output-item">
            <Split>
                <SplitItem className="dose-output-item-label">
                    <TextContent>
                        <Text>
                            {label}
                        </Text>
                    </TextContent>
                </SplitItem>
                <SplitItem>
                    {children}
                </SplitItem>
            </Split>
        </StackItem>
    );

}

export default DoseOutputSectionItem;