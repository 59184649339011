import React, { useState } from 'react';
import { PageSection, PageSectionVariants, EmptyState, EmptyStateBody, Drawer, DrawerContent, DrawerContentBody, Icon, EmptyStateHeader } from '@patternfly/react-core';
import { AngleLeftIcon, AngleRightIcon } from '@patternfly/react-icons'

import { useSelector } from 'react-redux';

import './customization-page.css';

import { useTranslation } from 'react-i18next';
import { configurationTargetSelectors } from '../../store/configurationTarget/configurationTargetSlice';
import { ModelType } from '../../store/global-types/customization-types';
import ContourCustomizationPage from './contour/ContourCustomizationPage';
import DoseCustomizationPage from './dose/DoseCustomizationPage';
import CustomizationSideBar from './CustomizationSideBar';


interface CustomizationPageProps {
    modelType: ModelType.Contouring | ModelType.Dose,
}

const CustomizationPage = (props: CustomizationPageProps) => {

    const { modelType } = props;

    const currentTarget = useSelector(configurationTargetSelectors.selectCurrent);

    const { t } = useTranslation();

    /** ID of the item we're currently focusing on (on what we last clicked on) */
    const [currentTreeViewFocus, setCurrentTreeViewFocus] = useState('');

    const [isDrawerExpanded, setIsDrawerExpanded] = React.useState(true);

    return (
        <PageSection variant={PageSectionVariants.dark} padding={{ default: 'noPadding' }}>

            {currentTarget === undefined ? (
                <EmptyState>
                    <EmptyStateHeader titleText={<>{t('selectConfigurationTarget.title')}</>} headingLevel="h4" />
                    <EmptyStateBody>{t('selectConfigurationTarget.message')}</EmptyStateBody>
                </EmptyState>
            ) : (

                <Drawer isExpanded={isDrawerExpanded} isInline position="left" className="sidebar-drawer">
                    <DrawerContent panelContent={(
                        <CustomizationSideBar
                            currentTreeViewFocus={currentTreeViewFocus}
                            setCurrentTreeViewFocus={setCurrentTreeViewFocus}
                            modelType={modelType}
                        />)}>
                        <DrawerContentBody>

                            <div className="sidebar-drawer-handle-bg">
                                <div className="sidebar-drawer-handle-container" onClick={() => { setIsDrawerExpanded(!isDrawerExpanded) }}>
                                    <div className="sidebar-drawer-handle">
                                        <span>{isDrawerExpanded ? <Icon size='sm'><AngleLeftIcon /></Icon> : <Icon size='sm'><AngleRightIcon /></Icon>}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="sidebar-drawer-content">
                                {
                                    modelType === ModelType.Contouring ? (
                                        <ContourCustomizationPage currentTreeViewFocus={currentTreeViewFocus} />
                                    ) : modelType === ModelType.Dose ? (
                                        <DoseCustomizationPage currentTreeViewFocus={currentTreeViewFocus} />
                                    ) : null
                                }
                            </div>
                        </DrawerContentBody>
                    </DrawerContent>
                </Drawer>
            )}
        </PageSection>
    );
}

export default CustomizationPage;
