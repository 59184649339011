import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { StoreState } from '../../../store/store';
import { doseSelectors, doseTargetRemoved } from '../../../store/dose/doseSlice';
import DoseTargetItem from './DoseTargetItem';

interface DoseTargetFormProps {
    targetId: string;
    targetIdList: string[];
}

const DoseTargetForm = (props: DoseTargetFormProps) => {

    const { targetId, targetIdList } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const target = useSelector((state: StoreState) => doseSelectors.selectTargetById(state, targetId));
    // const hasOriginalTarget = useSelector((state: StoreState) => doseSelectors.selectOriginalTargetById(state, targetId) !== undefined);

    const handleRemoveTarget = useCallback(() => {
        dispatch(doseTargetRemoved(targetId));
    }, [dispatch, targetId]);

    if (target === undefined) {
        return null;
    }

    return (
        <DoseTargetItem
            targetId={targetId}
            rowActions={[
                // {
                //     title: t('customizeRoi.showFullRoiDetails'),
                //     onClick: handleShowFullRoiDetails,
                // },
                // {
                //     title: t('customizeRoi.duplicateAsAdvancedRoi'),
                //     onClick: handleDuplicateRoi,
                // },
                // {
                //     title: t('customizeRoi.undoChanges'),
                //     onClick: handleUndoStructureChanges,
                //     disabled: !hasOriginalRoi || !roi.isModified,
                // }, {
                //     title: roi.isBuiltInRoi ? t('customizeRoi.revertNameToDefault') : t('customizeRoi.changeNameToMatchOperation'),
                //     onClick: handleRevertName,
                //     disabled: roi.name === roi.operation,
                // }, 
                {
                    title: t('customizationPage.dose.targets.removeTarget'),
                    onClick: handleRemoveTarget,
                },
            ]}
        />
    );
}

export default DoseTargetForm;
