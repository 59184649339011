
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { MVisionAppClient } from '../store/configurationTarget/mvision-client-list';
import Select, { createFilter, StylesConfig } from 'react-select';
import { StoreState } from '../store/store';

import './client-selector.css';
import { configurationTargetSelectors, currentConfigurationTargetSet } from '../store/configurationTarget/configurationTargetSlice';
import { contouringSelectors } from '../store/contouring/contouringSlice';

interface OptionType {
    value: string;
    label: string;
}

export const selectStyles: StylesConfig = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? 'grey' : '#17a2b8',
        backgroundColor: '#1c262d',
        color: '#ffffff',
        ':hover': {
            borderColor: '#17a2b8',
        },
        ':focus': {
            borderColor: '#17a2b8',
            boxShadow: '0 0 0 1px #17a2b8',
        },
        ':focus-within': {
            borderColor: '#17a2b8',
            boxShadow: '0 0 0 1px #17a2b8',
        },
    }),
    input: (baseStyles, state) => ({
        ...baseStyles,
        color: '#ffffff',
    }),
    menu: (baseStyles, state) => ({
        ...baseStyles,
        backgroundColor: '#1c262d',
    }),
    placeholder: (styles) => ({ ...styles, color: 'grey' }),
    singleValue: (styles) => ({ ...styles, color: 'grey' }),
    option: (baseStyles, state) => ({
        ...baseStyles,
        backgroundColor: state.isSelected ? '#17a2b8' : '#1c262d',
        ':hover': {
            backgroundColor: '#4a4949',
        },
        ':active': {
            backgroundColor: '#02c7d9',
        },
    }),
    dropdownIndicator: (baseStyles) => ({
        ...baseStyles,
        ':hover': {
            color: '#17a2b8',
        },
    }),
}

const ConfigurationTargetSelector = (props: { targetList: MVisionAppClient[] }) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const currentTarget = useSelector(configurationTargetSelectors.selectCurrent);
    const anyUnsavedChanges = useSelector(contouringSelectors.selectAnyUnsavedContouringChangesInApp);
    const isClientDataOperationInProgress = useSelector(
        (state: StoreState) => state.configurationTarget.isFetchingConfigurationTargetData || state.contouring.isAllModelCustomizationsResetInProgress);

    const changeConfigurationTarget = (newTargetId: string) => {
        if (newTargetId !== currentTarget?.userId) {
            const newValue = newTargetId === '' ? undefined : newTargetId;

            // prompt user to confirm if there are unsaved changes
            if (newValue !== currentTarget?.userId && anyUnsavedChanges && !window.confirm(t('confirm.changeConfigurationTarget') as string)) {
                return;
            }

            dispatch(currentConfigurationTargetSet(newValue));
        }
    }

    const dropdownValues: (MVisionAppClient | { userId: undefined, userName: string })[] = [];
    props.targetList.forEach(cl => dropdownValues.push(cl));

    const targetOptions = dropdownValues.map(dV => ({ value: dV.userId, label: dV.userName }));

    const selectedOption = currentTarget ? targetOptions.find(co => co.value === currentTarget.userId) : undefined;

    return (
        <div className="client-selector">
            <div className="client-selector-label">{t('topMenu.configurationTarget')}</div>
                { 
                    dropdownValues.length > 0 &&
                <Select
                    id="app-client-selector-form"
                    name="app-client-selector-form"
                    className="client-selector-dropdown"
                    isDisabled={isClientDataOperationInProgress}
                    onChange={(newAppClient) => {
                        if (newAppClient) {
                            const selectedOption = newAppClient as OptionType;
                            changeConfigurationTarget(selectedOption.value);
                        }
                    }}
                    value={selectedOption}
                    options={targetOptions}
                    filterOption={createFilter({ ignoreCase: true })}
                    placeholder={t('selectConfigurationTarget.placeholder')}
                    maxMenuHeight={700}
                    styles={selectStyles}
                />
                }
        </div>
    );
}

export default ConfigurationTargetSelector;
