import React from 'react';
import { PageSection, PageSectionVariants, EmptyState, EmptyStateBody, EmptyStateHeader } from '@patternfly/react-core';

import { useSelector } from 'react-redux';

import '../customization-page.css';
import ContourCustomizationToolbar from './ContourCustomizationToolbar';
import FullRoiDetailsModal from './FullRoiDetailsModal';
import { useTranslation } from 'react-i18next';
import { contouringSelectors } from '../../../store/contouring/contouringSlice';
import { CustomizationObjectType } from '../../../store/global-types/customization-types';
import { StoreState } from '../../../store/store';
import { appConfigSelectors } from '../../../store/appConfig/appConfigSlice';
import ContourCustomizationForm from './ContourCustomizationForm';


interface ContourCustomizationPageProps {
    /** ID of the item we're currently focusing on (on what we last clicked on) */
    currentTreeViewFocus: string,
}


const ContourCustomizationPage = (props: ContourCustomizationPageProps) => {

    const { currentTreeViewFocus } = props;

    const { t } = useTranslation();

    const baseIds = useSelector(contouringSelectors.selectCustomizationBaseIds);
    const outputIds = useSelector(contouringSelectors.selectOutputIds);
    const customizationFetchError = useSelector((state: StoreState) => state.contouring.customizationFetchError);
    const appConfig = useSelector(appConfigSelectors.selectAppConfig);


    // detect what type of view we're currently presenting
    let currentViewType = CustomizationObjectType.None;
    if (outputIds.includes(currentTreeViewFocus)) {
        currentViewType = CustomizationObjectType.CustomizationOutput;
    } else if (baseIds.includes(currentTreeViewFocus)) {
        currentViewType = CustomizationObjectType.CustomizationBase;
    }

    return (
        <>
            {appConfig && appConfig.showErrorsInUi && customizationFetchError !== null ? (
                <PageSection variant={PageSectionVariants.light}>
                    <EmptyState>
                        <EmptyStateHeader titleText={<>{t('error.generic')}</>} headingLevel="h4" />
                        <EmptyStateBody>{t('error.customizationFetchFailed', { customizationFetchError: customizationFetchError })}</EmptyStateBody>
                    </EmptyState>
                </PageSection>
            ) : (
                <>
                    <ContourCustomizationToolbar
                        currentView={currentViewType}
                        customizationOutputId={currentTreeViewFocus}
                    />
                    <FullRoiDetailsModal />
                    <PageSection variant={PageSectionVariants.light}>
                        {currentViewType === CustomizationObjectType.CustomizationOutput ? (
                            <ContourCustomizationForm
                                customizationOutputId={currentTreeViewFocus}
                            // modelValidation={activeValidation!} 
                            />
                        ) : (
                            <EmptyState>
                                <EmptyStateBody>{t('customizationPage.selectCustomization.message')}</EmptyStateBody>
                            </EmptyState>
                        )
                        }
                    </PageSection>
                </>
            )}
        </>
    );
}

export default ContourCustomizationPage;
