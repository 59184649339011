/***
 * This file contains customization-related types that are used across different domain types (contour, dose, etc)
 */


/** Visibility of a segmentation model */
export enum ModelVisibility {
    Default = 'DefaultVisibility',
    AlwaysShown = 'AlwaysShown',
    AlwaysHidden = 'AlwaysHidden'
}

/** Name of 'default' base customization */
export const DEFAULT_MODEL_CUSTOMIZATION_NAME = 'default';

/** The 'action' trigger used when a trigger is a DICOM rule instead of an AE Title rule. */
export const DICOM_RULE_TRIGGER_ACTION = 'seg';

/** Name of the filename metadata attribute. */
export const METADATA_FILENAME_ATTRIBUTE = 'filename';

export const DEFAULT_SUBSCRIPTION = 'default';

/** Model type for contouring models. */
export const SEGMENTATION_MODEL_TYPE = 'segmentation';

/** Model type for dose prediction models. */
export const DOSE_MODEL_TYPE = 'dose';

/** Type (algorithm) of a model. */
export enum ModelType { None = 'None', Contouring = 'Contouring', Dose = 'Dose' };

export enum ModelSelectionScope { None, ModelRules, AeTitleRule, DicomRule, DicomAttributeRule, All };

export enum DoseUnit { Gy = 'Gy', cGy = 'cGy', NotSet = 'not_set' };

export const DEFAULT_REGEX = '^$';

// validation messages
export const VALIDATION_MESSAGE_AE_TITLES_MUST_BE_UNIQUE = 'Action names in AE Title rules must be unique.';

export type CustomizationBaseWithModelName = { customizationBaseId: string, label: string };



/** Type of a customization view model object. For helper function use only. */
export enum CustomizationObjectType {
    None = "NONE",
    Model = "MODEL",
    CustomizationBase = "CUSTOMIZATION_BASE",
    CustomizationOutput = "CUSTOMIZATION_OUTPUT",
    Metadata = "METADATA",
    Roi = "ROI",
    Target = "TARGET",
    GlobalRoi = "GLOBAL_ROI",
    AeTitleRule = "AE_TITLE_RULE",
    DicomRule = "DICOM_RULE",
    DicomAttributeRule = "DICOM_ATTRIBUTE_RULE",
    TriggerRule = "TRIGGER_RULE",
    CodingScheme = "CODING_SCHEME",

    /** TODO: this is currently used ONLY for reading backend error messages */
    PhysicalProperties = 'PHYSICAL_PROPERTIES',
};

/** Helper type for constructing hierarchical tree views from customization data. Since these objects are
 * recursive and not flat it's best to avoid storing them in redux store.
 */
export type CustomizationTreeViewDataItem = {
    /** ID of this item. */
    id: string,
    /** Human-readable label of this item. */
    label: string,
    /** Recursive children of this item. */
    children: CustomizationTreeViewDataItem[],
    /** Soft link to parent object */
    parentId: string | undefined,
    /** The type that this item models. */
    type: CustomizationObjectType,
    // /** If defined, this ID will be used instead of this object's actual ID when performing actions on this item (e.g. "opening" it in UI).
    //  * Should match a given actionType. */
    // actionId?: string,
    // /** If defined, this CustomizationObjectType will be used instead of this object's actual type when performing actions on this item
    //  *  (e.g. "opening" it in UI). Should match a given actionId. */
    // actionType?: CustomizationObjectType,
    isModified: boolean,
    /** True if this object and all its descendants are valid, false if this or any of its descendants have validation errors. */
    isValid: boolean,
    /** True if this object is available for user use in the actual product, false otherwise. */
    isAvailable: boolean,
    /** True if this object is in the deprecated list, false otherwise. */
    isDeprecated: boolean,
    /** Model visibility of this item. Mainly applicable for models, other customization object types are not guaranteed to follow this visibility setting. */
    modelVisibility: ModelVisibility,
}


/** Parent type for modelling a contouring/dose/etc model and customization and selection/trigger rules that are related to it. */
export type Model = {
    /** Redux store ID of this model. */
    id: string;
    /** Type (algorithm) of this model. */
    modelType: ModelType;
    /** Name of the segmentation model. */
    modelName: string;
    /** Human-friendly label of the segmentation model. */
    label: string;
    /** Body part that this segmentation model covers. */
    bodyPart: string;
    /** An optional description of the segmentation model. */
    description: string;
    /** Visibility of this model: default, always shown, or always hidden. */
    visibility: ModelVisibility;
    /** Tags related to this model. Currently this value is only passed through. */
    tags: string[];

    /** Customizations and outputs that belong to this segmentation model. */
    customizations: string[]; // ModelCustomizationBase ids

    /** True if this segmentation model has unsaved changes, false otherwise. */
    isModified: boolean;

    // True if this model is available for user to use in the actual segmentation product, false otherwise.
    isAvailable: boolean;

    //True if model is in the deprecated list, false otherwise.
    isDeprecated: boolean;
};

/** A single model customization. One model customization can end up having multiple outputs (ie. result files). */
export type CustomizationBase = {
    /** Redux store ID of this customization base. */
    id: string;
    /** Name for this customization. */
    customizationName: string;
    /** An optional description of this customization base. */
    description: string;

    /** One or more outputs (ie. result files) that belong to this customization base. */
    outputs: string[]; // ModelCustomizationOutput ids

    /** Selection rules that trigger this customization. */
    aeTitleRules: string[];  // AeTitleRule ids
    dicomRules: string[];  // DicomRule ids

    /** Parent segmentation model ID of this customization base. */
    modelId: string;

    /** True if this customization base has unsaved changes, false otherwise. */
    isModified: boolean;
};

/** A single customization output (ie. a result file). A customization base contains one or more of these. Most domain types (contouring, dose, etc) have
 * their own versions of these.
 */
export type CustomizationOutput = {
    /** Redux store ID of this customization output. */
    id: string;

    /** Shorthand access to this output's filename metadata entry (an empty string if not defined). This is only used
     * for user-facing UI purposes -- the matching metadata entry is still the single source of truth for this attribute. */
    filename: string;

    /** Customization metadata relevant to this customization output. */
    metadata: string[]; // ModelCustomizationMetadataItem ids

    /** Parent customization base ID of this output. */
    modelCustomizationBaseId: string;

    /** True if this customization output has unsaved changes, false otherwise. */
    isModified: boolean;
};

/** A single customization output metadata item. */
export type OutputMetadataItem = {
    /** Redux ID of this customization metadata item. */
    id: string;
    /** Name of the metadata attribute. */
    attribute: string;
    /** Value of the metadata attribute. */
    value: string;

    /** True if this metadata item has unsaved changes, false otherwise. */
    isModified: boolean;
    /** If true, scroll the viewport to show this item when it's mounted into DOM. This is used to auto-scroll to newly added items. */
    scrollToView: boolean;

    /** Parent model customization output this metadata item belongs to. */
    modelCustomizationOutputId?: string;   // this is optional because it's usually set post-constructor
}


/** Base type for a model selection/trigger rule. */
type RuleBase = {
    /** Redux store ID for this rule. */
    id: string;

    /** Name of the subscription that this rule belongs to. */
    subscription: string;

    /** The model customization that this rule will trigger. This should match whatever the customizationName is set to. */
    modelCustomizationBaseId?: string;  // this is optional because it's usually set post-constructor

    aggregation: string | null;

    /** If true this rule can be edited by users. If false it's considered built-in and cannot be adjusted. */
    isEditable: boolean;
    /** If true this rule is highlighted in UI as being "new". */
    isNew: boolean;
    /** True if this rule has unsaved changes, false otherwise. */
    isModified: boolean;
    /** True if this rule has passed validation, false otherwise. */
    isValid: boolean;
    /** Validation message, if any. Undefined if validation has passed. */
    validationMessage: string | undefined;
}

/** Model selection/trigger rule that is triggered from a specific AE Title or segmentation backend API value. */
export type AeTitleRule = RuleBase & {
    /** Value of the AE Title or segmentation backend API action field that will trigger this rule. */
    action: string;
}

/** Model selection/trigger rule that is triggered from specific DICOM attribute values. */
export type DicomRule = RuleBase & {
    /** List of DICOM attributes and values that, on match, will trigger this rule. */
    dicomAttributes: string[]; // DicomAttributeRule ids
}

/** A DICOM attribute name and value set that can be used to trigger a DICOM rule. */
export type DicomAttributeRule = {
    /** Redux store ID of this DICOM attribute rule. */
    id: string;
    /** Name of the matching DICOM attribute that will trigger this rule. */
    attribute: string;
    /** Value (either direct or regexed) of the matching DICOM attribute that will trigger this rule. */
    value: string;
    /** Id of the DICOM rule this attribute belongs to. */
    parentDicomRuleId: string;

    /** If true this rule is highlighted in UI as being "new". */
    isNew: boolean,
    /** True if this rule has unsaved changes, false otherwise. */
    isModified: boolean;
    /** True if this rule has passed validation, false otherwise. */
    isValid: boolean;
}

/** A model can be triggered by one or more AE Title rules and/or DICOM rules. */
export type ModelTrigger = AeTitleRule | DicomRule;


// *** type guards ***

export const isModel = (obj: any): obj is Model => {
    const model = obj as Model;
    return model.modelName !== undefined && model.customizations !== undefined;
}

export const isCustomizationBase = (obj: any): obj is CustomizationBase => {
    const customization = obj as CustomizationBase;
    return customization.customizationName !== undefined && customization.outputs !== undefined
        && customization.aeTitleRules !== undefined && customization.dicomRules !== undefined
        && customization.modelId !== undefined;
}

export const isCustomizationOutput = (obj: any): obj is CustomizationOutput => {
    const output = obj as CustomizationOutput;
    return output.metadata !== undefined
        && output.modelCustomizationBaseId !== undefined
        && output.filename !== undefined;
}

export const isOutputMetadata = (obj: any): obj is OutputMetadataItem => {
    const metadata = obj as OutputMetadataItem;

    // ensure that the metadata object does NOT have the parentDicomRuleId as otherwise metadata and dicom attribute rule types are very similar
    return metadata.attribute !== undefined && metadata.value !== undefined && (obj as DicomAttributeRule).parentDicomRuleId === undefined;
}

const isRuleBase = (obj: any): obj is RuleBase => {
    const ruleBase = obj as RuleBase;
    return ruleBase.subscription !== undefined;
}

export const isAeTitleRule = (obj: any): obj is AeTitleRule => {
    const aeTitleRule = obj as AeTitleRule;
    return isRuleBase(obj) && aeTitleRule.action !== undefined && (obj as DicomRule).dicomAttributes === undefined;
}

export const isDicomRule = (obj: any): obj is DicomRule => {
    const dicomRule = obj as DicomRule;
    return isRuleBase(obj) && dicomRule.dicomAttributes !== undefined;
}

export const isDicomAttributeRule = (obj: any): obj is DicomAttributeRule => {
    const dicomAttributeRule = obj as DicomAttributeRule;
    return dicomAttributeRule.attribute !== undefined && dicomAttributeRule.value !== undefined && dicomAttributeRule.parentDicomRuleId !== undefined;
}

/** Converts a ModelType enum into a string that can be sent to a backend API endpoint */
export const convertModelTypeToApiString = (modelType: ModelType) => {
    let modelTypeHeader = '';
    switch (modelType) {
        case ModelType.Contouring:
            modelTypeHeader = 'contouring';
            break;
        case ModelType.Dose:
            modelTypeHeader = 'dose';
            break;
        default:
            throw new Error(`Unsupported model type: ${modelType}`);
    }

    return modelTypeHeader;
}
