import { isArray, isObject } from "lodash-es";
import { DoseCustomizationEntities, DoseCustomizationEntitiesForExport, DoseCustomizationOutput, DoseCustomizationOutputEntities, DoseRoi, DoseScaling, DoseScalingMethod, DoseTarget, PixelSpacing, TargetMethod, ZDoseCropping } from "./dose-types";
import { CustomizationBase, OutputMetadataItem, isAeTitleRule, METADATA_FILENAME_ATTRIBUTE, DoseUnit, ModelType, isDicomRule, DEFAULT_REGEX, Model, AeTitleRule, DicomRule, DicomAttributeRule } from "../global-types/customization-types";
import { createNewModelCustomizationBase, generateNewId, UI_ID_ATTRIBUTE } from "../global-types/customization-helpers";
import { assertExpectedProps } from "../../util/expected-prop";
import { _convertOutputMetadataViewModelToJson, convertCustomizationJsonObjectToViewModels, convertCustomizationViewModelsToJson, convertSingleCustomizationJsonEntryToViewModelObjects, CustomizationBaseExtractResult, extractOutputMetadata, ModelTriggersByCustomizationNames } from "../global-types/view-model-conversion-helpers";
import { naturalSort } from "../../util/sort";
import { enumFromStringOrDefault } from "../../util/enum";


export const createNewDoseCustomizationOutput = (
    metadataIds: string[],
    roiIds: string[],
    targetIds: string[],
    doseScaling: DoseScaling,
    pixelSpacing: PixelSpacing,
    zDoseCropping: ZDoseCropping,
    isRTPlanIncluded: boolean,
    machineType: string,
    machineName: string,
    customizationBaseId: string,
    filename?: string,
    id?: string,
    isModified?: boolean): DoseCustomizationOutput => ({
        id: id || generateNewId(),
        metadata: metadataIds,
        rois: roiIds,
        targets: targetIds,
        doseScaling: doseScaling,
        pixelSpacing: pixelSpacing,
        zDoseCropping: zDoseCropping,
        isRTPlanIncluded: isRTPlanIncluded,
        machineType: machineType,
        machineName: machineName,
        modelCustomizationBaseId: customizationBaseId,
        filename: filename || '',
        isModified: isModified || false,
    });

export const createNewDoseScaling = (dose: number, isActive: boolean, method: DoseScalingMethod, volume: number): DoseScaling => ({
    dose,
    isActive,
    method,
    volume
});

export const createNewZDoseCropping = (value: number, isActive: boolean): ZDoseCropping => ({
    value,
    isActive,
});

export const createNewDoseRoi = (name: string, regExp: string,
    isMandatory: boolean, outputId?: string,
    id?: string, isModified?: boolean): DoseRoi => ({
        id: id || generateNewId(),

        name: name,
        regExp: regExp,
        isIncluded: true,
        isMandatory: isMandatory,

        isModified: isModified || false,
        outputId: outputId,
    });

export const createNewDoseTarget = (method: TargetMethod, unit: DoseUnit, regExp: string,
    prescription: number | null, outputId?: string,
    id?: string, isModified?: boolean): DoseTarget => ({
        id: id || generateNewId(),

        method: method,
        unit: unit,
        regExp: regExp,
        prescription: prescription,

        isModified: isModified || false,
        outputId: outputId,
    });



export const defaultCreateNewDoseRoi = (outputId: string): DoseRoi => {
    return createNewDoseRoi('', DEFAULT_REGEX, true, outputId, undefined, true);
}

export const defaultCreateNewDoseTarget = (outputId: string): DoseTarget => {
    return createNewDoseTarget(TargetMethod.Variable, DoseUnit.Gy, DEFAULT_REGEX, null, outputId, undefined, true);
}


/** Convert dose customization JSON from config API into flattened internal view model representation.
 * 
 * Regarding sorting: if any sorting is done, it's generally done to try to ensure order by original
 * name using naturalSort. Dynamic sorting during user operation is generally not wanted as it could
 * erratically shift the position of the element on the page. Set the 'noSorting' prop to 'true' if sorting
 * is not wanted (e.g. for roundtrip unit tests).
 */
export const convertDoseJsonObjectToViewModels = (jsonObject: any, noSorting: boolean = false): DoseCustomizationEntities => {

    const results = convertCustomizationJsonObjectToViewModels(jsonObject, ModelType.Dose, noSorting);

    // return converted results with following notes:
    // - any undefined items are treated as empty arrays (in an unlikely case there's e.g. no selection rules at all)
    // - ignore any fields related to other algorithms (although if there were anything like that present then 
    //   the earlier conversion function should have already thrown)
    return {
        models: results.models || [],
        customizationBases: results.customizationBases || [],
        doseOutputs: results.doseOutputs || [],
        outputMetadata: results.outputMetadata || [],
        doseRois: results.doseRois || [],
        doseTargets: results.doseTargets || [],
        aeTitleRules: results.aeTitleRules || [],
        dicomRules: results.dicomRules || [],
        dicomAttributeRules: results.dicomAttributeRules || [],
    }
}

/** Convert model customization DTO/JSON containing just ONE dose model customization from backend 
 * API into flattened internal view model representation. Returns matching view model objects.
 * 
 * Regarding sorting: if any sorting is done, it's generally done to try to ensure order by original
 * name using naturalSort. Dynamic sorting during user operation is generally not wanted as it could
 * erratically shift the position of the element on the page. Set the 'noSorting' prop to 'true' if sorting
 * is not wanted (e.g. for roundtrip unit tests).
 */
export const convertSingleModelJsonToDoseOutput = (jsonObject: any, noSorting: boolean = false): DoseCustomizationOutputEntities => {
    if (!jsonObject || !isObject(jsonObject)) {
        throw new Error('Invalid JSON object provided for contouring customizations conversion.');
    }

    const modelCollection: Model[] = [];
    const customizationBaseCollection: CustomizationBase[] = [];
    const outputCollection: DoseCustomizationOutput[] = [];
    const metadataCollection: OutputMetadataItem[] = [];
    const roiCollection: DoseRoi[] = [];
    const targetCollection: DoseTarget[] = [];
    const aeTitleRuleCollection: AeTitleRule[] = [];
    const dicomRuleCollection: DicomRule[] = [];
    const dicomAttributeRuleCollection: DicomAttributeRule[] = [];

    const assertMessage = 'Unexpected contouring model/structure customization format.';

    convertSingleCustomizationJsonEntryToViewModelObjects(
        jsonObject,

        modelCollection,
        customizationBaseCollection,
        metadataCollection,
        aeTitleRuleCollection,
        dicomRuleCollection,
        dicomAttributeRuleCollection,

        [], [],

        outputCollection,
        roiCollection,
        targetCollection,

        assertMessage,
        ModelType.Dose,
        noSorting
    );

    return {
        outputs: outputCollection,
        metadata: metadataCollection,
        rois: roiCollection,
        targets: targetCollection,
    }
}


/**
 * Helper function for converting a customization base and all its outputs and other child objects into dose customization entities.
 * You shouldn't need to call this function manually.
 */
export const _convertDoseCustomizationJsonToViewModel = (
    modelCustomizationBaseResult: CustomizationBaseExtractResult,
    modelId: string,
    doseOutputCollection: DoseCustomizationOutput[],
    outputMetadataCollection: OutputMetadataItem[],
    doseRoiCollection: DoseRoi[],
    doseTargetCollection: DoseTarget[],
    modelTriggersByCustomizationNames: ModelTriggersByCustomizationNames,
    assertMessage: string,
    noSorting: boolean,
): CustomizationBase => {
    const { customizationId, customizationName, customizationDescription, dtoOutputs } = modelCustomizationBaseResult;

    // each 'file' will become its own customization output object
    const outputs: DoseCustomizationOutput[] = dtoOutputs.map((customizationOutputEntry: any) => {

        // assert that output entries look correct
        assertExpectedProps(customizationOutputEntry, [
            { propName: 'metadata', propType: 'array' },
            { propName: 'rois', propType: 'array' },
            { propName: 'dose_scaling', propType: 'object' },
            { propName: 'include_rtplan', propType: 'boolean' },
            { propName: 'machine_type', propType: 'string' },
            { propName: 'machine_name', propType: 'string' },
            { propName: 'pixel_spacing', propType: 'array' },
            { propName: 'targets', propType: 'array' },
            { propName: 'z_dose_cropping', propType: 'object' },
        ], assertMessage);

        // extract metadata
        const metadata = extractOutputMetadata(customizationOutputEntry, assertMessage);

        // extract rois
        const rois: DoseRoi[] = customizationOutputEntry['rois'].map((roiEntry: any) => {
            // assert that roi entries look correct
            assertExpectedProps(roiEntry, [
                { propName: 'roi_name', propType: 'string' },
                { propName: 'reg_exp', propType: 'string' },
                { propName: 'included', propType: 'boolean' },
                { propName: 'mandatory', propType: 'boolean' },
            ], assertMessage);

            return createNewDoseRoi(
                roiEntry['roi_name'],
                roiEntry['reg_exp'],
                roiEntry['mandatory']);
        });

        // extract targets
        const targets: DoseTarget[] = customizationOutputEntry['targets'].map((targetEntry: any) => {
            // assert that target entries look correct (ignore prescription here)
            assertExpectedProps(targetEntry, [
                { propName: 'method', propType: 'string' },
                { propName: 'reg_exp', propType: 'string' },
                { propName: 'unit', propType: 'string' },
            ], assertMessage);

            const targetMethod = enumFromStringOrDefault(TargetMethod, targetEntry['method'], TargetMethod.NotSet);
            const doseUnit = enumFromStringOrDefault(DoseUnit, targetEntry['unit'], DoseUnit.NotSet);

            // unparsed 'default' values are not allowed!
            if (targetMethod === TargetMethod.NotSet) { throw new Error(`Could not parse target method from '${targetEntry['method']}`); }
            if (doseUnit === DoseUnit.NotSet) { throw new Error(`Could not parse dose unit from '${targetEntry['unit']}`); }

            return createNewDoseTarget(
                targetMethod,
                doseUnit,
                targetEntry['reg_exp'],
                targetEntry['prescription'] as number || null,
            );
        });

        // collect ids. ensure roi id order within models
        const metadataIds = metadata.map(m => m.id);
        const roiIds = noSorting ? rois.map(r => r.id) : naturalSort(rois, 'name').map(r => r.id);
        const targetIds = noSorting ? targets.map(r => r.id) : naturalSort(targets, 'regExp').map(t => t.id);
        const filename = metadata.find(m => m.attribute === METADATA_FILENAME_ATTRIBUTE)?.value;

        // extract dose scaling
        const doseScalingEntry = customizationOutputEntry['dose_scaling'];
        assertExpectedProps(doseScalingEntry, [
            { propName: 'dose', propType: 'number' },
            { propName: 'attribute', propType: 'boolean' },
            { propName: 'method', propType: 'string' },
            { propName: 'volume', propType: 'number' },
        ], assertMessage);

        const doseScalingMethod = enumFromStringOrDefault(DoseScalingMethod, doseScalingEntry['method'], DoseScalingMethod.NotSet);

        // unparsed 'default' values are not allowed!
        if (doseScalingMethod === DoseScalingMethod.NotSet) { throw new Error(`Could not parse dose scaling method from '${doseScalingEntry['method']}`); }

        const doseScaling = createNewDoseScaling(
            doseScalingEntry['dose'],
            doseScalingEntry['attribute'],
            doseScalingMethod,
            doseScalingEntry['volume'],
        );

        // extract Z-dose cropping
        const zDoseCroppingEntry = customizationOutputEntry['z_dose_cropping'];
        assertExpectedProps(zDoseCroppingEntry, [
            { propName: 'value', propType: 'number' },
            { propName: 'attribute', propType: 'boolean' },
        ], assertMessage);

        const zDoseCropping = createNewZDoseCropping(
            zDoseCroppingEntry['value'],
            zDoseCroppingEntry['attribute'],
        );

        // extract pixel spacing
        let pixelSpacing = customizationOutputEntry['pixel_spacing'];
        if (pixelSpacing !== undefined && !isArray(pixelSpacing)) {
            throw new Error('Could not parse pixel spacing');
        }
        if (isArray(pixelSpacing)) {
            if (pixelSpacing.length > 3) {
                throw new Error(`Unsupported pixel spacing (${pixelSpacing.join('; ')})`);
            } else if (pixelSpacing.length === 0) {
                pixelSpacing = undefined;
            }
        }


        const output = createNewDoseCustomizationOutput(
            metadataIds,
            roiIds,
            targetIds,
            doseScaling,
            pixelSpacing,
            zDoseCropping,
            customizationOutputEntry['include_rtplan'],
            customizationOutputEntry['machine_type'],
            customizationOutputEntry['machine_name'],
            customizationId,
            filename);

        // update parent ids to child objects
        metadata.forEach(m => m.modelCustomizationOutputId = output.id);
        rois.forEach(r => r.outputId = output.id);
        targets.forEach(t => t.outputId = output.id);

        // add newly created objects into collections that will eventually be put into redux store
        outputMetadataCollection.push(...metadata);
        doseRoiCollection.push(...rois);
        doseTargetCollection.push(...targets);

        return output;
    });

    const outputIds = outputs.map(o => o.id);
    const matchingTriggers = modelTriggersByCustomizationNames[customizationName] || [];
    const aeTitleRules = matchingTriggers.filter(isAeTitleRule);
    const dicomRules = matchingTriggers.filter(isDicomRule);
    const aeTitleRuleIds = aeTitleRules.map(t => t.id);
    const dicomRuleIds = dicomRules.map(t => t.id);
    const customization = createNewModelCustomizationBase(customizationName, customizationDescription, outputIds, aeTitleRuleIds, dicomRuleIds, modelId, customizationId);

    // update matching customization IDs now that we have a customization object
    matchingTriggers.forEach(t => t.modelCustomizationBaseId = customization.id);

    // add newly created objects into collections that will eventually be put into redux store
    doseOutputCollection.push(...outputs);

    return customization;
}





/** Convert dose customization view models to JSON presentation.
 * @param contouringEntities The dose customization view model entities to convert to JSON.
 * @param noUiId Internal IDs for the view models needed for UI functionality will not be included in the export if true, and will be included
 * if set to false. Defaults to false. Including internal IDs is needed when sending data to backend to be able to cross-reference received
 * errors to their matching UI view models, but these IDs should be omitted when this JSON will be user-facing (e.g. when exported directly
 * as a downloaded file) or when running certain unit tests (e.g. round-trip tests will break if extra UI ID fields are present).
 * @returns An array of model JSON objects, ready to be sent to backend.
 */
export const convertDoseViewModelsToJson = (contouringEntities: Partial<DoseCustomizationEntitiesForExport>, noUiId: boolean = false): any => {

    const jsonModels = convertCustomizationViewModelsToJson(contouringEntities, ModelType.Dose, noUiId);

    return jsonModels;
}


/**
 * Convert internal view model representation of dose output objects and related children
 * into a model customization JSON hierarchy. You shouldn't need to call this manually.
 * @param jsonCustomization Result JSON will be embedded into this JSON.
 * @param output Dose output view model to convert to JSON.
 * @param outputMetadata Collection of output metadata related to this customization set.
 * @param doseRois Collection of ROI customizations related to this customization set.
 * @param doseTargets Collection of ROI targets related to this customization set.
 * @param errorMessage Error message to throw on failure.
 * @param noUiId Marks if internal UI IDs should be included in converted JSON.
 */
export const _convertDoseCustomizationOutputToJson = (
    jsonCustomization: any,
    output: DoseCustomizationOutput,
    outputMetadata: OutputMetadataItem[],
    doseRois: DoseRoi[],
    doseTargets: DoseTarget[],
    errorMessage: string,
    noUiId: boolean = false): any => {
    const jsonObject: any[] = [];

    if (output === undefined) { throw new Error(errorMessage); }
    if (output.doseScaling.method === DoseScalingMethod.NotSet) {
        throw new Error(`Unsupported dose scaling method: ${output.doseScaling.method}`);
    }
    const jsonOutput: any = {
        'metadata': [],
        'rois': [],
        'targets': [],
        'dose_scaling': {
            'attribute': output.doseScaling.isActive,
            'method': output.doseScaling.method,
            'volume': output.doseScaling.volume,
            'dose': output.doseScaling.dose
        },
        'pixel_spacing': output.pixelSpacing,
        'z_dose_cropping': {
            'attribute': output.zDoseCropping.isActive,
            'value': output.zDoseCropping.value
        },
        'include_rtplan': output.isRTPlanIncluded,
        'machine_type': output.machineType,
        'machine_name': output.machineName,
    };
    if (!noUiId) { jsonOutput[UI_ID_ATTRIBUTE] = output.id }

    for (const metadataId of output.metadata) {
        const metadata = outputMetadata.find(m => m.id === metadataId);
        if (metadata === undefined) { throw new Error(errorMessage); }
        _convertOutputMetadataViewModelToJson(jsonOutput, metadata, noUiId);
    }

    for (const roiId of output.rois) {
        const roi = doseRois.find(r => r.id === roiId);
        if (roi === undefined) { throw new Error(errorMessage); }

        const jsonRoi: any = {
            'roi_name': roi.name.trim(),
            'included': roi.isIncluded,
            'mandatory': roi.isMandatory,
            'reg_exp': roi.regExp,
        };
        if (!noUiId) { jsonRoi[UI_ID_ATTRIBUTE] = roi.id }
        jsonOutput['rois'].push(jsonRoi);
    }

    for (const targetId of output.targets) {
        const target = doseTargets.find(t => t.id === targetId);
        if (target === undefined) { throw new Error(errorMessage); }
        if (target.unit === DoseUnit.NotSet) { throw new Error(`Dose unit is not set.`); }

        const jsonTarget: any = {
            'method': target.method,
            'unit': target.unit,
            'reg_exp': target.regExp,
            'prescription': target.prescription
        };
        if (!noUiId) { jsonTarget[UI_ID_ATTRIBUTE] = target.id }
        jsonOutput['targets'].push(jsonTarget);
    }

    jsonCustomization['files'].push(jsonOutput);
}

export const duplicateRoiCustomization = (roi: DoseRoi, outputId?: string): DoseRoi => {
    return {
        id: generateNewId(),
        name: roi.name,
        isMandatory: roi.isMandatory,
        regExp: roi.regExp,
        isIncluded: roi.isIncluded,
        isModified: true,
        outputId: outputId,
    };
}

export const duplicateTargetCustomization = (target: DoseTarget, outputId?: string): DoseTarget => {
    return {
        id: generateNewId(),
        method: target.method,
        prescription: target.prescription,
        unit: target.unit,
        regExp: target.regExp,
        isModified: true,
        outputId: outputId,
    };
}


export const readModelCustomizationJsonFile = (json: any): Promise<DoseCustomizationEntities | null> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            try {
                const jsonObject = JSON.parse(reader.result as string);

                const modelCustomizationImport = convertDoseJsonObjectToViewModels(jsonObject);
                resolve(modelCustomizationImport);
            } catch (e) {
                reject(e);
            }
        };

        reader.onerror = (error) => {
            reject(error);
        };

        reader.readAsText(json);
    });
};
