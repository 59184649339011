// *** OTHER GLOBALS ***

import { HelpDocumentLocation, LangKey } from "./i18n";
import { newGuid } from "./util/math";
import { getFilenameSafeTimestamp } from "./util/string-convert";

/** Default app name of MVision Configuration -- NOTE: use translation pack name ('appTitle') via i18next in UI instead of this! */
export const DEFAULT_APP_NAME = 'MVision Configuration';
export const APP_NAME_SHORT = 'mvconfigui';
export const getDefaultAppName = () => DEFAULT_APP_NAME;

/** The "version" of the app that's displayed for the user. This needs to be set MANUALLY for each release! */
export const DISPLAY_VERSION = '1.2.7';

/// *** APP REGISTRATIONS, AUTHORIZATIONS ***

export const MVAPI_DEFAULT = 'MVAPI_CLINICAL';

export const MVISION_AUTHORITY = 'https://login.microsoftonline.com/mvision.ai';

export const mvapiClinicalAppRegistrationApplicationClientId = '49dd79c5-077f-4fa1-a055-4752e20025bd';
// export const mvdapiprodUrl = 'https://mvd-api-westeurope.azurewebsites.net';

export const LOGGED_OUT_URI = '/logged-out';

/**
 * Help document locations for each supported language. Fallback language (helpFileFallbackLangKey below),
 * if defined, is used if a language is not defined here.
 */
export const helpFileLocations: HelpDocumentLocation[] = [
    { langKey: 'en-US', url: 'doc/help.md' },
    { langKey: 'fr-FR', url: 'doc/help-fr.md' },
];

let helpFileFallbackLangKey: LangKey | undefined = 'en-US';

/** A fallback language to use for help file (probably 'en-US'), or undefined if fallback should not be used if a help file
 * is not available for current language.
 */
export function getHelpFileFallbackLangKey(): LangKey | undefined {
    return helpFileFallbackLangKey;
}

/** Sets helpFileFallbackLangKey -- mainly meant for unit test use. */
export function setHelpFileFallbackLangKey(fallbackLangKey: LangKey | undefined) {
    helpFileFallbackLangKey = fallbackLangKey;
}


// *** SESSION ID ***

/** Session ID is used to identify this particular web client session in API calls. */
let sessionId: string;

/** (Re-)generates and sets a new session ID. */
export function generateNewSessionId() {
    sessionId = `${APP_NAME_SHORT}-${getFilenameSafeTimestamp()}-${newGuid()}`.substring(0, 64);
    console.info(`Session ID: ${sessionId}`);
}

/** Returns the current session ID. */
export function getSessionId(): string {
    if (!sessionId) {
        generateNewSessionId();
    }
    
    return sessionId;
}

// *** CLIENT LISTS ***

/** Daemon client names start with this prefix */
export const daemonPrefix = 'mvdaemon-';
