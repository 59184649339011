import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, FormGroup, Alert } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';
import { IAction, Td, Tr } from '@patternfly/react-table';

import TextOverrideField from '../../../components/text-override-field';
import { getFormValidationErrorMessage } from '../../../store/global-types/form-errors';
import { defaultValidation, FormValidationState } from '../../../components/form-validation-state';
import { StoreState } from '../../../store/store';
import { CustomizationObjectType } from '../../../store/global-types/customization-types';
import { doseRoiIsMandatorySet, doseRoiNameSet, doseRoiRegExpSet, doseSelectors } from '../../../store/dose/doseSlice';
import { makeSelectFormValidationErrorsForRoi } from '../../../store/dose/doseSelectors';
import { columnCssNames, columnIds } from './DoseRoiTable';
import RegexField from '../../../components/regex-field';
import ValidationHelperText from '../../../components/validation-helper-text';
import { ItemActionsColumn } from '../contour/ItemActionsColumn';

interface RoiItemProps {
    roiId: string;
    rowActions?: IAction[];
}

const DoseRoiItem = (props: RoiItemProps) => {
    const { roiId, rowActions } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const roi = useSelector((state: StoreState) => doseSelectors.selectRoiById(state, roiId));
    const originalRoi = useSelector((state: StoreState) => doseSelectors.selectOriginalRoiById(state, roiId));
    const validationError = useSelector((state: StoreState) => doseSelectors.selectCustomizationValidationError(state, roiId));

    const selectFormValidationErrorsForRoi = useMemo(makeSelectFormValidationErrorsForRoi, []);
    const formValidationErrors = useSelector((state: StoreState) => selectFormValidationErrorsForRoi(state, roiId));

    const [nameFieldValidation, setNameFieldValidation] = useState<FormValidationState>(defaultValidation);

    /** Set non-blocking form validation errors generated in redux store reducers to be visible in this form's normal UI validation. */
    useEffect(() => {
        if (formValidationErrors.length > 0) {
            setNameFieldValidation({ validated: 'error', helperTextInvalid: formValidationErrors.map(e => getFormValidationErrorMessage(e.errorType)).join(' ') });
        } else {
            setNameFieldValidation(defaultValidation);
        }
    }, [formValidationErrors, roi]);

    const handleNameChange = useCallback((name: string) => {
        dispatch(doseRoiNameSet({ id: roiId, name, }));
    }, [dispatch, roiId]);

    const handleMandatoryChange = useCallback((_: unknown, isMandatory: boolean) => {
        dispatch(doseRoiIsMandatorySet({ id: roiId, isMandatory }));
    }, [dispatch, roiId]);

    const handleRegExpChange = useCallback((regExp: string) => {
        dispatch(doseRoiRegExpSet({ id: roiId, regExp, }));
    }, [dispatch, roiId]);

    if (roi === undefined) {
        return null;
    }

    const prefix = `roi-${roi.id}`;
    const nameFieldId = `${prefix}-name`;
    const mandatoryFieldId = `${prefix}-mandatory`;

    const hasValidationError = validationError !== undefined && validationError.type === CustomizationObjectType.Roi;

    return (
        <>
            <Tr key={roiId} className={`roi-item ${hasValidationError ? 'validation-error' : undefined}`}>
                <Td className={columnCssNames.modified}><div className="roi-item-is-modified" title="This customization has unsaved changes.">{roi.isModified ? '*' : <>&nbsp;</>}</div></Td>

                <Td dataLabel={columnIds.name} className={columnCssNames.name}>
                    <div>
                        <FormGroup
                            fieldId={`field-${nameFieldId}`}
                            className={nameFieldValidation.validated === 'error' ? 'validation-error' : ''}
                        >
                            <TextOverrideField
                                defaultText={originalRoi ? originalRoi.name : roi.name}
                                textValue={roi.name}
                                id={nameFieldId}
                                onChange={handleNameChange}
                                allowEmptyField={true}
                            />
                            {nameFieldValidation.validated !== 'default' && (
                                <ValidationHelperText
                                    validated={nameFieldValidation.validated}
                                    helperText={nameFieldValidation.helperTextInvalid}
                                />
                            )}
                        </FormGroup>
                    </div>
                </Td>

                <Td dataLabel={columnIds.isMandatory} className={columnCssNames.isMandatory}>
                    <div>
                        <Switch
                            id={mandatoryFieldId}
                            isChecked={roi.isMandatory}
                            onChange={handleMandatoryChange}
                            label={t('toggle.roiMandatoryInStructureSet.on')}
                            labelOff={t('toggle.roiMandatoryInStructureSet.off')}
                        />
                    </div>
                </Td>

                <Td dataLabel={columnIds.matchPattern} className={columnCssNames.matchPattern}>
                    <div>
                        <RegexField
                            id={roiId}
                            label={undefined}
                            onChange={handleRegExpChange}
                            regexValue={roi.regExp}
                            alwaysUseRegex
                        />
                    </div>
                </Td>

                <Td isActionCell>
                    {rowActions && <ItemActionsColumn items={rowActions} />}
                </Td>
            </Tr>

            {hasValidationError && (
                <Tr className="roi-item validation-error validation-error-box">
                    <Td dataLabel="Validation error" noPadding={true} colSpan={100}>
                        <Alert variant="danger" isInline isPlain isExpandable title={`Validation error: ${validationError.message}`}>
                            {validationError.field && (<div>Field: {validationError.field}</div>)}
                            <div>Error type: {validationError.detail}</div>
                            <div>Error ctx: {validationError.ctx}</div>
                        </Alert>
                    </Td>
                </Tr>
            )}
        </>
    );
}

export default DoseRoiItem;
