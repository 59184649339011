import { PageSection, TextContent, Text, PageSectionVariants, Alert, Divider, EmptyState, EmptyStateBody, EmptyStateHeader } from '@patternfly/react-core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';


import './daemon-config-page.css';
import { useTranslation } from 'react-i18next';
import DaemonConfigItem from './DaemonConfigItem';
import { StoreState } from '../../store/store';
import { appConfigSelectors } from '../../store/appConfig/appConfigSlice';
import { configurationTargetSelectors } from '../../store/configurationTarget/configurationTargetSlice';
import { daemonConfigsFetched } from '../../store/daemon/daemonSlice';


const DaemonConfigPage = () => {

    const appConfig = useSelector(appConfigSelectors.selectAppConfig);
    const currentTarget = useSelector(configurationTargetSelectors.selectCurrent);

    // reload daemon configs whenever the page is reloaded, re-opened, or client is changed
    const dispatch = useDispatch();
    const { t } = useTranslation();
    useEffect(() => {
        dispatch(daemonConfigsFetched(currentTarget));
    }, [dispatch, currentTarget]);

    const daemonConfigs = useSelector((state: StoreState) => state.daemon.daemonConfigs);
    const daemonConfigsError = useSelector((state: StoreState) => state.daemon.daemonConfigsError);

    return (
        <>
            <PageSection variant={PageSectionVariants.default}>
                <TextContent>
                    <Text component="h1">{t('page.daemonConfig.title')}</Text>
                </TextContent>
            </PageSection>

            <PageSection variant={PageSectionVariants.light}>

                {appConfig && appConfig.showErrorsInUi && daemonConfigsError !== null && (
                    <PageSection>
                        <Alert variant="danger" isInline title="An error occurred when trying to retrieve daemon configuration">
                            <p>An error occurred when trying to retrieve daemon configuration:</p>
                            <p>{daemonConfigsError}</p>
                        </Alert>
                    </PageSection>
                )}

                {!currentTarget && (
                    <>
                        <EmptyState>
                            <EmptyStateHeader titleText={<>{t('daemonConfigPage.noConfigurationTarget.title')}</>} headingLevel="h4" />
                            <EmptyStateBody>{t('daemonConfigPage.noConfigurationTarget.body')}</EmptyStateBody>
                        </EmptyState>
                    </>
                )}

                {currentTarget && daemonConfigsError === null && daemonConfigs !== null && daemonConfigs.length === 0 && (
                    <>
                        <EmptyState>
                            <EmptyStateHeader titleText={<>{t('daemonConfigPage.noDaemonConfig.title')}</>} headingLevel="h4" />
                            <EmptyStateBody>{t('daemonConfigPage.noDaemonConfig.body')}</EmptyStateBody>
                        </EmptyState>
                    </>
                )}

                {currentTarget && daemonConfigsError === null && daemonConfigs !== null && daemonConfigs.length > 0 && (
                    <>
                        <PageSection variant={PageSectionVariants.light} className="limit-width">

                            {/* Show a help message when 2+ daemon configs are available */}
                            {daemonConfigs.length > 1 && (
                                <TextContent className="content-header">
                                    <Text component="p">{t('daemonConfigPage.configCountAvailable', { count: daemonConfigs.length })}</Text>
                                </TextContent>)}

                            {daemonConfigs.map((d, index) => (
                                <div className="daemon-config-block" key={d.sessionId}>
                                    <DaemonConfigItem daemonConfig={d} runningNumber={daemonConfigs.length > 1 ? index + 1 : undefined} />
                                    {index !== daemonConfigs.length - 1 && (<Divider className="content-divider" />)}
                                </div>
                            ))}
                        </PageSection>

                    </>
                )}

            </PageSection>


        </>
    );
}

export default DaemonConfigPage;
