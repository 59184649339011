import { FormHelperText, HelperText, HelperTextItem } from '@patternfly/react-core';
import { ExclamationCircleIcon, InfoCircleIcon } from '@patternfly/react-icons';

interface ValidationHelperTextProps {
    validated: 'error' | 'warning' | 'success';
    helperText?: string | null;
}

const ValidationHelperText = ({ validated, helperText }: ValidationHelperTextProps) => {
    // Return null if no helper text is provided
    if (!helperText) return null;

    return (
        <FormHelperText>
            <HelperText>
                {validated === 'error' ? (
                    <HelperTextItem icon={<ExclamationCircleIcon />} variant="error">
                        {helperText}
                    </HelperTextItem>
                ) : (
                    <HelperTextItem icon={<InfoCircleIcon />} variant="default">
                        {helperText}
                    </HelperTextItem>
                )}
            </HelperText>
        </FormHelperText>
    );
};

export default ValidationHelperText;
