import { delay, put, call, take, takeEvery, select } from "typed-redux-saga";
import { get } from "lodash-es";
import { WebConfigurationClient } from "../../web-apis/web-configuration";
import { appVersionSet, labelingFetched, labelingSet, newVersionAlertSnoozed, newVersionAlertSnoozeSet } from "./appVersionSlice";
import { appInitializationFailed } from "../appStatus/appStatusSlice";
import { NEW_VERSION_SNOOZE_TIME_IN_HOURS } from "./appVersionTypes";
import { callApi } from "../sagas";
import { appConfigSelectors } from "../appConfig/appConfigSlice";
import { convertSupportedModelTypeToActualModelType } from "../appConfig/appDeploymentInfoTypes";

// export function* watchServerVersionPeriodically() {
//     const webClient = new RTViewerWebClient();
//     yield* delay(1000);
//     while (true) {
//         const appVersion = yield* call(() => webClient.getAppVersionInfo());
//         yield* put(setAppVersion(appVersion));

//         // wait for 1 hour before doing another version check
//         yield* delay(1 * 60 * 60 * 1000);
//     }
// }

// wait for 2 hours before doing another version check
const WAIT_TIME_BETWEEN_VERSION_CHECKS_MS = 2 * 60 * 60 * 1000;


export function* watchServerVersionPeriodically() {
    while (true) {
        yield* delay(WAIT_TIME_BETWEEN_VERSION_CHECKS_MS);
        yield* call(getAppVersion);
    }
}

export function* getAppVersion() {
    try {
        const appVersion = yield* call(WebConfigurationClient.getAppVersionInfo);
        yield* put(appVersionSet(appVersion));
    }
    catch (error) {
        console.log('An error occurred when trying to retrieve app version:');
        console.error(error);
        yield* put(appInitializationFailed((error as Error).toString()));
    }
}

function* fetchLabelingSaga() {
    const appDeploymentInfo = yield* select(appConfigSelectors.selectAppDeploymentInfo);
    const modelTypes = appDeploymentInfo?.supportedModelTypes.map(s => convertSupportedModelTypeToActualModelType(s)) || [];

    yield* callApi({
        doApiCall: function* (client) {
            const productLabel = yield* call(async () => client.fetchLabelingAsync(modelTypes));
            return productLabel;
        },
        onSuccess: function* (result) {
            yield* put(labelingSet({ combinedLabeling: result }));
        },
        onFailure: function* (error) {
            console.error('Was not able to fetch product label');
            const errorMessage = `${get(error, 'problem', 'Error')}: ${get(error, 'message', 'Unknown error')}`;
            yield* put(labelingSet({ combinedLabeling: null, error: errorMessage }));
        }
    });
}


function* watchVersionAlertSnoozeSaga() {
    while (true) {
        yield* take(newVersionAlertSnoozed);
        const snoozeForMs = NEW_VERSION_SNOOZE_TIME_IN_HOURS * 60 * 60 * 1000;
        yield* put(newVersionAlertSnoozeSet(true));
        yield* delay(snoozeForMs)
        yield* put(newVersionAlertSnoozeSet(false));
    }
}

function* watchFetchLabelingSaga() {
    yield* takeEvery(labelingFetched, fetchLabelingSaga);
}


/** Returns all relevant watches to be added to a main root watch saga */
export function getWatchesForAppVersionSagas() {
    return [
        // watchServerVersionPeriodically,  // this one is handled separately in main app initialization saga
        watchVersionAlertSnoozeSaga(),
        watchFetchLabelingSaga(),
    ];
}
