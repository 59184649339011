import { IAction, Td, Tr } from '@patternfly/react-table';
import { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup, Alert } from '@patternfly/react-core';

import { StoreState } from '../../../store/store';
import { doseSelectors, doseTargetMethodSet, doseTargetPrescriptionSet, doseTargetRegExpSet, doseTargetUnitSet } from '../../../store/dose/doseSlice';
import { CustomizationObjectType, DoseUnit } from '../../../store/global-types/customization-types';
import RegexField from '../../../components/regex-field';
import { columnCssNames, columnIds } from './DoseTargetTable';
import FormSelect from '../../../components/form-select';
import { TargetMethod } from '../../../store/dose/dose-types';
import { isSomeEnum } from '../../../util/enum';
import NumberInput from '../../../components/number-input';
import { ItemActionsColumn } from '../contour/ItemActionsColumn';

interface DoseTargetItemProps {
    targetId: string;
    extraColumnsBefore?: ReactNode[],
    extraColumnsAfter?: ReactNode[],
    rowActions?: IAction[];
}

const DoseTargetItem = (props: DoseTargetItemProps) => {
    const { targetId, rowActions, extraColumnsBefore, extraColumnsAfter } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const target = useSelector((state: StoreState) => doseSelectors.selectTargetById(state, targetId));
    const originalTarget = useSelector((state: StoreState) => doseSelectors.selectOriginalTargetById(state, targetId));
    const validationError = useSelector((state: StoreState) => doseSelectors.selectCustomizationValidationError(state, targetId));

    const handleTargetMethodChanged = useCallback((method: string) => {
        if (isSomeEnum(TargetMethod)(method)) {
            dispatch(doseTargetMethodSet({ id: targetId, targetMethod: method }));
        }
    }, [dispatch, targetId]);

    const handleTargetUnitChanged = useCallback((unit: string) => {
        if (isSomeEnum(DoseUnit)(unit)) {
            dispatch(doseTargetUnitSet({ id: targetId, doseUnit: unit }));
        }
    }, [dispatch, targetId]);

    const handleTargetRegExpChanged = useCallback((regExp: string) => {
        dispatch(doseTargetRegExpSet({ id: targetId, regExp, }));
    }, [dispatch, targetId]);

    const handleTargetPrescriptionChanged = useCallback((prescription: number | null) => {
        dispatch(doseTargetPrescriptionSet({ id: targetId, prescription }));
    }, [dispatch, targetId]);

    // const selectFormValidationErrorsForRoi = useMemo(makeSelectFormValidationErrorsForRoi, []);
    // const formValidationErrors = useSelector((state: StoreState) => selectFormValidationErrorsForRoi(state, roiId));

    // const [executeScroll, scrollRef] = useScroll<HTMLDivElement>();
    // const [isHighlightedItem, setIsHighlightedItem] = useState(false);

    // /** Scroll the viewport to a correct position if an advanced ROI was just added. */
    // useEffect(() => {
    //     if (roi && scrollRef && isDoseRoi(roi) && roi.scrollToView) {
    //         setIsHighlightedItem(true);
    //         dispatch(scrollToViewFromRoiCustomizationRemoved(roiId));
    //         executeScroll();
    //     }
    // }, [executeScroll, roi, scrollRef, dispatch, roiId]);

    // const [nameFieldValidation, setNameFieldValidation] = useState<FormValidationState>(defaultValidation);
    // const [operationFieldValidation, setOperationFieldValidation] = useState<FormValidationState>(defaultValidation);
    // const [isRoiOperationModalOpen, setIsRoiOperationModalOpen] = useState(false);

    // /** Set non-blocking form validation errors generated in redux store reducers to be visible in this form's normal UI validation. */
    // useEffect(() => {
    //     if (formValidationErrors.length > 0) {
    //         setNameFieldValidation({ validated: 'error', helperTextInvalid: formValidationErrors.map(e => getFormValidationErrorMessage(e.errorType)).join(' ') });
    //     } else {
    //         setNameFieldValidation(defaultValidation);
    //     }
    // }, [formValidationErrors, roi]);

    if (target === undefined) {
        return null;
    }

    const prefix = `target-${target.id}`;
    // const nameFieldId = `${prefix}-name-override`;
    // const includedFieldId = `${prefix}-included`;

    // TODO: support highlighting
    // const highlight = isHighlightedItem ? 'highlighted-item' : '';
    const highlight = '';

    const hasValidationError = validationError !== undefined && validationError.type === CustomizationObjectType.Target;

    return (
        <>
            <Tr key={targetId} className={`roi-item ${hasValidationError ? 'validation-error' : undefined} ${highlight}`}>
                <Td className={columnCssNames.modified}><div className="roi-item-is-modified" title="This customization has unsaved changes.">{target.isModified ? '*' : <>&nbsp;</>}</div></Td>

                {extraColumnsBefore && extraColumnsBefore.map(extraColumn => extraColumn)}

                <Td dataLabel={columnIds.method} className={columnCssNames.method}>
                    <FormSelect
                        items={[
                            { label: t('customizationPage.dose.targets.targetMethod.fixed'), value: TargetMethod.Fixed.toString() },
                            { label: t('customizationPage.dose.targets.targetMethod.variable'), value: TargetMethod.Variable.toString() }
                        ]}
                        selectedValue={target.method.toString()}
                        onSelect={handleTargetMethodChanged}
                    />
                </Td>

                <Td dataLabel={columnIds.unit} className={columnCssNames.unit}>
                    <FormSelect
                        items={[
                            { label: t('common.dose.unit.gy'), value: DoseUnit.Gy.toString() },
                            { label: t('common.dose.unit.cgy'), value: DoseUnit.cGy.toString() }
                        ]}
                        selectedValue={target.unit.toString()}
                        onSelect={handleTargetUnitChanged}
                    />
                </Td>

                <Td dataLabel={columnIds.matchPattern} className={columnCssNames.matchPattern}>
                    <div>
                        <RegexField
                            id={targetId}
                            label={undefined}
                            onChange={handleTargetRegExpChanged}
                            regexValue={target.regExp}
                            alwaysUseRegex
                        />
                    </div>
                </Td>

                <Td dataLabel={columnIds.prescription} className={columnCssNames.prescription}>
                    <FormGroup>
                        <NumberInput
                            fieldId={`prescription-field-for-${targetId}`}
                            onChange={handleTargetPrescriptionChanged}
                            defaultValue={target.prescription || undefined}
                        />
                    </FormGroup>
                </Td>

                {extraColumnsAfter && extraColumnsAfter.map(extraColumn => extraColumn)}

                <Td isActionCell>
                    {rowActions && <ItemActionsColumn items={rowActions} />}
                </Td>
            </Tr>

            {hasValidationError && (
                <Tr className="roi-item validation-error validation-error-box">
                    <Td dataLabel="Validation error" noPadding={true} colSpan={100}>
                        <Alert variant="danger" isInline isPlain isExpandable title={`Validation error: ${validationError.message}`}>
                            {validationError.field && (<div>Field: {validationError.field}</div>)}
                            <div>Error type: {validationError.detail}</div>
                            <div>Error ctx: {validationError.ctx}</div>
                        </Alert>
                    </Td>
                </Tr>
            )}

            {/* {isTargetOperationModalOpen && (
                <TargetOperationModal isOpen={isTargetOperationModalOpen} onConfirm={handleOperationChanged} onClose={() => setIsTargetOperationModalOpen(false)} operation={roi.operation} />
            )} */}

        </>
    );
}

export default DoseTargetItem;
