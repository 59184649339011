import { FormGroup, MenuToggle, MenuToggleElement, Select, SelectList, SelectOption, } from '@patternfly/react-core';
import { isString } from 'lodash-es';
import React, { ReactNode, useCallback, useEffect } from 'react';

import './form-select.css';

export type FormSelectElement = { value: string, label: string | ReactNode };

interface FormSelectProps {
    onSelect: (value: string) => void,
    selectedValue: string,
    items: FormSelectElement[],
    isDisabled?: boolean,
    /** CSS width of the element, e.g. '200px'  */
    cssWidth?: string,
    className?: string,
}

const FormSelect = (props: FormSelectProps) => {
    const { selectedValue, onSelect, items, isDisabled, cssWidth, className } = props;

    const [isOpen, setIsOpen] = React.useState(false);
    const [selectItems, setSelectItems] = React.useState<ReactNode>(null);

    // initialize dropdown values
    useEffect(() => {
        if (items.length === 0) {
            setSelectItems(null);
        } else {
            setSelectItems(
                <SelectList>
                    {items.map((item) => (<SelectOption key={item.value} value={item.value}>{item.label}</SelectOption>))}
                </SelectList>
            );
        }
    }, [items]);


    const handleToggleOpen = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);

    const handleOpenChange = useCallback((value: boolean) => {
        setIsOpen(value);
    }, []);

    // const onFocus = useCallback(() => {
    //     const element = document.getElementById('toggle-basic');
    //     element.focus();
    // }, []);

    const handleSelect = useCallback((_: unknown, value?: string | number) => {
        setIsOpen(false);
        // onFocus();
        if (isString(value)) {
            onSelect(value);
        }
    }, [onSelect]);

    const getSelectedLabel = useCallback((value: string) => {
        const item = items.find(i => i.value === value);
        return !!item ? item.label : '';
    }, []);

    const toggle = (toggleRef: React.Ref<MenuToggleElement>) => (
        <MenuToggle
            ref={toggleRef}
            onClick={handleToggleOpen}
            isExpanded={isOpen}
            isDisabled={isDisabled}
            className={className}
            style={
                {
                    width: cssWidth || '200px'
                } as React.CSSProperties
            }
        >
            {getSelectedLabel(selectedValue)}
        </MenuToggle>
    );

    return (
        <FormGroup className="form-dropdown">
            <Select
                onSelect={handleSelect}
                isOpen={isOpen}
                onOpenChange={handleOpenChange}
                selected={selectedValue}
                toggle={toggle}
                shouldFocusToggleOnSelect
            >
                {selectItems}
            </Select>
        </FormGroup>
    );
}

export default FormSelect;
