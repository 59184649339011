import { PageSection, TextContent, Text, PageSectionVariants, Alert, Divider, EmptyState, EmptyStateBody, EmptyStateHeader } from '@patternfly/react-core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CurrentLicense from './CurrentLicense';

import './license-page.css';
import { useTranslation } from 'react-i18next';
import UpdateLicense from './UpdateLicense';
import { StoreState } from '../../store/store';
import { appConfigSelectors } from '../../store/appConfig/appConfigSlice';
import { configurationTargetSelectors } from '../../store/configurationTarget/configurationTargetSlice';
import { licenseStatusFetched } from '../../store/license/licenseSlice';


const LicensePage = () => {

    const appConfig = useSelector(appConfigSelectors.selectAppConfig);
    const currentTarget = useSelector(configurationTargetSelectors.selectCurrent);

    // reload license status whenever the page is reloaded, re-opened, or client is changed
    const dispatch = useDispatch();
    const { t } = useTranslation();
    useEffect(() => {
        dispatch(licenseStatusFetched(currentTarget));
    }, [dispatch, currentTarget]);

    const licenseStatus = useSelector((state: StoreState) => state.license.licenseStatus);
    const licenseStatusError = useSelector((state: StoreState) => state.license.licenseStatusError);

    return (
        <>
            <PageSection variant={PageSectionVariants.default}>
                <TextContent>
                    <Text component="h1">{t('page.license.title')}</Text>
                </TextContent>
            </PageSection>

            <PageSection variant={PageSectionVariants.light}>

                {appConfig && appConfig.showErrorsInUi && licenseStatusError !== null && (
                    <PageSection>
                        <Alert variant="danger" isInline title="An error occurred when trying to retrieve license information">
                            <p>An error occurred when trying to retrieve license information:</p>
                            <p>{licenseStatusError}</p>
                        </Alert>
                    </PageSection>
                )}

                {!currentTarget && (
                    <>
                        <EmptyState>
                            <EmptyStateHeader titleText={<>{t('licensePage.noConfigurationTarget.title')}</>} headingLevel="h4" />
                            <EmptyStateBody>{t('licensePage.noConfigurationTarget.body')}</EmptyStateBody>
                        </EmptyState>
                    </>
                )}

                {currentTarget && licenseStatusError === null && licenseStatus !== null && licenseStatus.length === 0 && (
                    <>
                        <EmptyState>
                            <EmptyStateHeader titleText={<>{t('licensePage.noLicense.title')}</>} headingLevel="h4" />
                            <EmptyStateBody>{t('licensePage.noLicense.body')}</EmptyStateBody>
                        </EmptyState>
                    </>
                )}

                {currentTarget && licenseStatusError === null && licenseStatus !== null && licenseStatus.length > 0 && (
                    <>
                        <PageSection variant={PageSectionVariants.light} className="limit-width">

                            <TextContent className="content-header">
                                <Text component="h2">{t('licensePage.currentLicense', { count: licenseStatus.length })}</Text>
                            </TextContent>
                            {licenseStatus.map((ls, index) => (
                                <div className="license-block" key={ls.id}>
                                    {licenseStatus.length > 1 && (<TextContent className="content-header"><Text component="h3">{t('licensePage.licenseNumber', { number: index + 1 })}</Text></TextContent>)}
                                    <CurrentLicense licenseStatus={ls} />
                                    {index !== licenseStatus.length - 1 && (<Divider className="content-divider" />)}
                                </div>
                            ))}
                        </PageSection>

                        {appConfig && appConfig.allowLicenseUpdate && (<><Divider className="content-divider" /><UpdateLicense /></>)}

                    </>
                )}

            </PageSection>


        </>
    );
}

export default LicensePage;
