import React, { memo, useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, FormGroup, InputGroup, InputGroupItem, Level, LevelItem, List, ListItem, Modal, ModalVariant, Popover, Stack, StackItem, Text } from '@patternfly/react-core';
import { OutlinedQuestionCircleIcon } from '@patternfly/react-icons';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import DebouncedTextInput from './debounced-text-input';
import { appConfigSelectors } from '../store/appConfig/appConfigSlice';
import { getHasAcknowledgedRegexServiceDisclaimer, setHasAcknowledgedRegexServiceDisclaimer } from '../util/local-storage';
import { DeploymentConfigInfo } from '../store/appConfig/appDeploymentInfoTypes';
import { literalTextToRegex, regexToLiteralText } from '../util/string-convert';

import './regex-field.css';


interface RegexFieldProps {
    id: string,
    label: string | undefined,
    onChange: (regexValue: string) => void,
    regexValue: string | undefined,
    /** If true the 'Enable regular expressions' checkbox is hidden, regex mode is always on,
     * and regex usage acknowledgment won't be prompted or used. */
    alwaysUseRegex?: boolean,
    /** If true the help popover and button will be hidden. */
    hideHelp?: boolean,
    title?: string,
}

const RegexField = (props: RegexFieldProps) => {
    const { id, label, onChange, regexValue, alwaysUseRegex, hideHelp, title } = props;

    const { t } = useTranslation();
    const deploymentConfigInfo = useSelector(appConfigSelectors.selectAppDeploymentInfo);

    const [isRegexModeOn, setRegexModeOn] = useState(false);
    const [openRegexConfirmModal, setOpenRegexConfirmModal] = useState<boolean>(false);
    const [plainTextValue, setPlainTextValue] = useState<string | null>(null);

    // initial setup
    useEffect(() => {
        // start with regex enabled if current string cannot be converted to plain text
        const plainText = regexToLiteralText(regexValue || '');
        setPlainTextValue(plainText);
        if (alwaysUseRegex || plainText === null) { setRegexModeOn(true); }
    }, []);

    // change regex mode appropriately immediately when internal value is changed
    const handleImmediateChange = useCallback((value: string) => {
        if (isRegexModeOn) {
            // regex mode is turned on, input value is regex
            const plainText = regexToLiteralText(value);
            setPlainTextValue(plainText);
        } else {
            // regex mode is turned off, input value is plain text
            setPlainTextValue(value);
        }
    }, [isRegexModeOn]);

    const handleAcknowledgeRegexUsage = useCallback(() => {
        setHasAcknowledgedRegexServiceDisclaimer(true);
        setOpenRegexConfirmModal(false);
    }, []);

    const handleDoNotAcknowledgeRegexUsage = useCallback(() => {
        setRegexModeOn(false);
        setOpenRegexConfirmModal(false);
    }, []);

    const handleToggleRegexEnabled = useCallback(() => {

        if (!isRegexModeOn) {
            // ask user to acknowledge enabling regex mode when they first try to turn it on
            const acknowledgedRegexServiceDisclaimer = getHasAcknowledgedRegexServiceDisclaimer();
            if (!acknowledgedRegexServiceDisclaimer) {
                setOpenRegexConfirmModal(true);
            }
        }

        setRegexModeOn(!isRegexModeOn);
    }, [setRegexModeOn, isRegexModeOn]);

    const handleDebouncedChange = useCallback((value: string) => {
        const regexValue = isRegexModeOn ? value : literalTextToRegex(value);
        onChange(regexValue);
    }, [onChange, isRegexModeOn]);

    const regexRef = React.createRef<HTMLDivElement>();

    return (
        <>
            <Stack className={isRegexModeOn ? 'regex-input' : ''}>
                <StackItem>
                    <FormGroup
                        label={label}
                        fieldId={`regex-field-for-${id}`}
                        title={title}
                    // validated={modelValidation.GetNameValidationState(structureCustomization.name)}
                    // helperTextInvalid={modelValidation.GetNameValidationText(structureCustomization.name)}
                    // helperTextInvalidIcon={<ExclamationCircleIcon />}
                    >
                        <InputGroup ref={regexRef}>
                            <InputGroupItem isFill>
                                <DebouncedTextInput
                                    fieldId={`regex-field-for-${id}`}
                                    onDebouncedChange={handleDebouncedChange}
                                    onImmediateChange={handleImmediateChange}
                                    className={`input-field ${isRegexModeOn ? 'regex-input' : ''}`}
                                    defaultValue={isRegexModeOn ? regexValue : regexToLiteralText(regexValue || '') || ''}
                                />
                            </InputGroupItem>
                            {alwaysUseRegex && !hideHelp && (
                                <InputGroupItem>
                                    <RegexHelp deploymentConfigInfo={deploymentConfigInfo} appendTo={regexRef.current} />
                                </InputGroupItem>
                            )}
                        </InputGroup>
                    </FormGroup>
                </StackItem>
                <StackItem>
                    <Level>
                        {!alwaysUseRegex &&
                            <LevelItem>
                                <Checkbox
                                    label={t('regex.enableRegEx')}
                                    isChecked={isRegexModeOn}
                                    onChange={handleToggleRegexEnabled}
                                    id={`dicom-attribute-rule-regex-toggle-${id}`}
                                    isDisabled={plainTextValue === null}
                                />
                            </LevelItem>}
                        {!alwaysUseRegex && !hideHelp &&
                            <LevelItem className="regex-help">
                                <RegexHelp deploymentConfigInfo={deploymentConfigInfo} />
                            </LevelItem>}
                    </Level>
                </StackItem>
            </Stack>
            <Modal
                variant={ModalVariant.small}
                title={t('regex.disclaimer')}
                isOpen={!alwaysUseRegex && openRegexConfirmModal}
                showClose
                onClose={handleDoNotAcknowledgeRegexUsage}
                actions={[
                    <Button key="confirmDisclaimer" variant="primary" onClick={handleAcknowledgeRegexUsage}>{t('regex.disclaimer.acknowledge')}</Button>,
                    <Button key="cancelDisclaimer" variant="tertiary" onClick={handleDoNotAcknowledgeRegexUsage}>{t('common.cancel')}</Button>
                ]}
            >
                <Text className='regex-disclaimer-modal-upper-text'>{t('regex.disclaimer.beforeUsingRegex')}:</Text>
                <List>
                    <ListItem className='regex-disclaimer-modal-list-item'>{t('regex.disclaimer.incorrectSyntax')}.</ListItem>
                    <ListItem className='regex-disclaimer-modal-list-item'>{t('regex.disclaimer.contactSupport')}.</ListItem>
                    <ListItem className='regex-disclaimer-modal-list-item'>{t('regex.disclaimer.validationText')}.</ListItem>
                </List>
            </Modal>
        </>
    );
}



interface RegexHelpProps {
    deploymentConfigInfo: DeploymentConfigInfo | undefined,
    appendTo?: HTMLElement | HTMLDivElement | ((ref?: HTMLElement) => HTMLElement) | "inline" | undefined | null,
}

const RegexHelp = (props: RegexHelpProps) => {
    const { deploymentConfigInfo, appendTo } = props;

    const { t } = useTranslation();

    return (
        <Popover
            headerContent={<div>Regular expressions</div>}
            bodyContent={(
                <div className="regex-help">
                    <div>{appendTo === undefined ? t('regex.help.toggle') : t('regex.help.alwaysOn')}</div>
                    {deploymentConfigInfo?.regexHelpFile && (<div><Trans i18nKey="regex.help.helpFile">A <a target="_blank" rel="noreferrer" href={deploymentConfigInfo.regexHelpFile}>Regular Expressions cheat sheet</a> is available.</Trans></div>)}
                    {deploymentConfigInfo?.regexHelpUrl && (<div><Trans i18nKey="regex.help.website">This <a target="_blank" rel="noreferrer" href={deploymentConfigInfo.regexHelpUrl}>website</a> can also be used as an aid with writing the Regular Expressions.</Trans></div>)}
                </div>
            )
            }
            appendTo={appendTo || undefined}
            withFocusTrap={true}
        >
            {appendTo === undefined ? (
                <Button variant="plain"><OutlinedQuestionCircleIcon /></Button>
            ) : (
                <Button variant="control" className="regex-appended-help-button"><OutlinedQuestionCircleIcon /></Button>
            )}
        </Popover>
    );
}

export default memo(RegexField);
