import { useCallback, useState } from 'react';
import { FormGroup, Level, LevelItem, Button, TextInput, Modal, ModalVariant, Divider, DropdownItem, Dropdown, MenuToggle, MenuToggleElement } from '@patternfly/react-core';
import { ArrowRightIcon, EllipsisVIcon } from '@patternfly/react-icons';
import { useTranslation } from 'react-i18next';

import ModelSelectionRuleWrapper from './SelectionRuleWrapper';
import DebouncedTextInput from '../../components/debounced-text-input';
import { ModelSelectionUndoState } from './ModelSelectionPage';
import { AeTitleRule, CustomizationObjectType, ModelSelectionScope, ModelType } from '../../store/global-types/customization-types';
import { BackendValidationErrorViewModel } from '../../store/global-types/store-errors';
import ValidationHelperText from '../../components/validation-helper-text';

interface AeTitleRuleFormProps {
    modelType: ModelType,
    aeTitleRule: AeTitleRule,
    validationError: BackendValidationErrorViewModel | undefined,
    runningNumber: number,
    setUndoState: (undoState: ModelSelectionUndoState) => void,
    isUndoDisabled?: boolean,
    onAeTitleRuleActionUpdated: (action: string) => void,
    onAeTitleRuleRemoved: () => void,
}

const generateAeTitle = (action: string): string => `MV${action.toUpperCase()}`;

const AeTitleRuleForm = (props: AeTitleRuleFormProps) => {
    const { modelType, aeTitleRule, validationError, runningNumber, setUndoState, isUndoDisabled, onAeTitleRuleActionUpdated, onAeTitleRuleRemoved } = props;

    const { t } = useTranslation();

    const [isActionListOpen, setActionListOpenState] = useState(false);
    const [isRemoveAeTitleRuleModalOpen, setRemoveAeTitleRuleModalOpenState] = useState(false);

    const handleAeTitleActionChange = useCallback((action: string) => {
        onAeTitleRuleActionUpdated(action);
        // dispatch(aeTitleRuleActionUpdated({ id: aeTitleRuleId, action }));
    }, [aeTitleRule, onAeTitleRuleActionUpdated]);

    const handleRemoveAeTitleRuleButtonClick = useCallback(() => {
        setActionListOpenState(false);
        setRemoveAeTitleRuleModalOpenState(true);
    }, []);

    const handleRemoveAeTitleRuleConfirmation = useCallback(() => {
        setRemoveAeTitleRuleModalOpenState(false);
        onAeTitleRuleRemoved();
        // dispatch(aeTitleRuleRemoved(aeTitleRuleId));
    }, [aeTitleRule, onAeTitleRuleRemoved]);

    const handleUndo = useCallback(() => {
        setUndoState({ isModalOpen: true, scope: ModelSelectionScope.AeTitleRule, id: aeTitleRule.id });
        setActionListOpenState(false)
    }, [aeTitleRule, setUndoState]);

    const fieldId = aeTitleRule.id;
    const formValidationState = aeTitleRule.isValid ? 'default' : 'error';

    const dropdownItems = [<DropdownItem
        key="removeAeTitleRule" isDisabled={!aeTitleRule.isEditable}
        title={aeTitleRule.isEditable ? undefined : t('selectionPage.defaultNamesCannotBeRemoved')}
        onClick={handleRemoveAeTitleRuleButtonClick}>{t('selectionPage.removeAeTitleRule')}</DropdownItem>];
    if (!isUndoDisabled) {
        dropdownItems.unshift(<DropdownItem key="undoAeTitleRuleChanges" isDisabled={!aeTitleRule.isModified || aeTitleRule.isNew}
            onClick={handleUndo}>{t('selectionPage.undoAeTitleRuleChanges')}</DropdownItem>);
    }

    const hasValidationError = validationError !== undefined && validationError.type === CustomizationObjectType.AeTitleRule;

    // render either an ae title field or a dicom attribute field
    return (
        <ModelSelectionRuleWrapper
            key={aeTitleRule.id}
            title={t('selectionPage.aeTitleRuleNumber', { number: runningNumber })}
            isModified={aeTitleRule.isModified}
            className="ae-title-rule"
            hasValidationError={hasValidationError}
            validationError={validationError}>

            <div className="rule-item">
                <Level className="ae-title-row">
                    <LevelItem className="ae-title-action-column">
                        <FormGroup
                            label={t('selectionPage.actionName')}
                            fieldId={`action-name-field-for-${fieldId}`}
                            title={`The action name that can be used to access this model. AE Title is generated from this field.${aeTitleRule.isEditable ? '' : ' Default action names/AE Titles cannot be modified, but you can add new ones.'}`}
                        >
                            <DebouncedTextInput
                                fieldId={`action-name-field-for-${fieldId}`}
                                onDebouncedChange={handleAeTitleActionChange}
                                className="ae-title"
                                defaultValue={aeTitleRule.action}
                                isDisabled={!aeTitleRule.isEditable}
                                validated={formValidationState}
                            />
                            {formValidationState !== 'default' && (
                                <ValidationHelperText
                                    validated={formValidationState}
                                    helperText={aeTitleRule.validationMessage}
                                />
                            )}
                        </FormGroup>
                    </LevelItem>
                    <LevelItem className="ae-title-arrow">
                        <ArrowRightIcon />
                    </LevelItem>
                    <LevelItem className="ae-title-aetitle-column">
                        <FormGroup
                            label={t('selectionPage.generatedAeTitle')}
                            fieldId={`ae-title-field-for-${fieldId}`}
                            title="AE Title is generated from the action name. AE Titles cannot be changed directly -- adjust the action name instead."
                        >
                            <TextInput
                                className="ae-title"
                                type="text"
                                id={`ae-title-field-for-${fieldId}`}
                                value={generateAeTitle(aeTitleRule.action)}
                                isDisabled
                            />
                        </FormGroup>
                    </LevelItem>
                    <LevelItem style={{alignItems:'bottom'}}>
                        <Dropdown
                            toggle={(toggleRef: React.Ref<MenuToggleElement>) => <MenuToggle ref={toggleRef} onClick={() => setActionListOpenState(!isActionListOpen)} variant='plain'><EllipsisVIcon/></MenuToggle>}
                            isPlain
                            popperProps={{position: 'right'}}
                            isOpen={isActionListOpen}
                            shouldFocusFirstItemOnOpen={false}
                            onOpenChange={() => setActionListOpenState(!isActionListOpen)}
                        >{dropdownItems}</Dropdown>
                    </LevelItem>
                </Level>
            </div>
            <Modal
                variant={ModalVariant.small}
                title={t('selectionPage.confirmRemoveAeTitleRule')}
                isOpen={isRemoveAeTitleRuleModalOpen}
                onClose={() => setRemoveAeTitleRuleModalOpenState(false)}
                actions={[
                    <Button key="confirmRemoval" variant="danger" onClick={handleRemoveAeTitleRuleConfirmation}>{t('selectionPage.removeAeTitleRule')}</Button>,
                    <Button key="cancel" variant="tertiary" onClick={() => setRemoveAeTitleRuleModalOpenState(false)}>{t('common.cancel')}</Button>
                ]}
            >
                Do you want to remove this entire AE Title rule ("{generateAeTitle(aeTitleRule.action)}")? Note that your changes won't be saved until you click on "Save".
            </Modal>
            <Divider />
        </ModelSelectionRuleWrapper>
    );
}

export default AeTitleRuleForm;
