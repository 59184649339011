import { TextInput } from '@patternfly/react-core';
import React, { useLayoutEffect, useState } from 'react';

const DEFAULT_DEBOUNCE_TIME_MS = 300;

interface DebouncedTextInputProps {
    className?: string,
    fieldId: string,
    /** Patternfly validation state. */
    validated?: 'success' | 'warning' | 'error' | 'default',
    isDisabled?: boolean,
    /** Initial value of the component. Can also be used to set/revert/reset/undo the state of the component externally. */
    defaultValue?: string,
    /** Function to call after the component has not have had any changes to it after the supplied debounce time. Put e.g. 
     * a dispatch function here. */
    onDebouncedChange: (value: string) => void,
    /** Function to call immediately when the internal value has changed. It's not recommended to dispatch from this function. */
    onImmediateChange?: (value: string) => void,
    /** Time to wait for no further user input to the component before calling the supplied onChange function. */
    debounceTimeMs?: number,
    placeholder?: string,
    inputRef?: React.RefObject<HTMLInputElement>,
    type?:
    | 'text'
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'month'
    | 'number'
    | 'password'
    | 'search'
    | 'tel'
    | 'time'
    | 'url',
    onBlur?: () => void,
}

/** Internal text change object. */
type TextChange = {
    text: string,
    noDispatch?: boolean,
}

/** Used for internal debounce state tracking. */
enum DebounceState { NotDebouncing, StartingDebounce, FinishingDebounce };

/** A text input component that stores its value internally before calling the supplied onChange function after a debounce time
 * with whatever is the current latest value in the component. This is useful for sending values into redux store that may result
 * in expensive computation, but where it's important that the UI interaction remains fast and not laggy.
 * 
 * Uses Patternfly's TextInput component internally.
 */
const DebouncedTextInput = (props: DebouncedTextInputProps) => {

    const { defaultValue, debounceTimeMs, onDebouncedChange: onChange, placeholder, inputRef, type, onBlur, onImmediateChange } = props;

    // use debouncing to eliminate ui lag because we do constant computation (validation etc) whenever this value is changed
    const [currentFieldText, setFieldText] = useState<TextChange>({ text: defaultValue || '' });
    const [debounceState, setDebounceState] = useState(DebounceState.NotDebouncing);

    // set the debounce function -- do not call onChange until after the component has not been interacted with
    // for a set time (i.e. debounceTimeMs). Set appropriate internal state so we can distinguish between
    // intended component interaction by user and when the supplied value is being changed externally (in
    // which case we do not want to call onChange at all)
    useLayoutEffect(() => {
        if (debounceState === DebounceState.StartingDebounce) {
            const debouncedHandleChange = () => {
                if (debounceState === DebounceState.StartingDebounce && !currentFieldText.noDispatch) {
                    onChange(currentFieldText.text);
                }
                setDebounceState(DebounceState.FinishingDebounce);
            }
            const debounceEffect = setTimeout(() => debouncedHandleChange(), debounceTimeMs || DEFAULT_DEBOUNCE_TIME_MS);
            return () => { clearTimeout(debounceEffect); };
        }
    }, [currentFieldText, debounceTimeMs, debounceState, onChange]);

    // handle external changes & debounce state cleanup
    useLayoutEffect(() => {
        if (debounceState === DebounceState.NotDebouncing && defaultValue !== currentFieldText.text) {
            // text input value is being adjusted outside of component, don't call onChange
            setFieldText({ text: defaultValue || '', noDispatch: true });
        } else if (debounceState === DebounceState.FinishingDebounce) {
            setDebounceState(DebounceState.NotDebouncing);
        }
    }, [defaultValue, debounceState, currentFieldText.text]);

    const handleFieldTextChange = (_: unknown, value: string) => {
        setFieldText({ text: value });
        setDebounceState(DebounceState.StartingDebounce);
        if (onImmediateChange) { onImmediateChange(value); }
    }

    return (
        <TextInput
            className={props.className || ''}
            type={type || 'text'}
            id={props.fieldId}
            onChange={handleFieldTextChange}
            value={currentFieldText.text}
            ref={inputRef}
            validated={props.validated || 'default'}
            // onBlur={handleBlur}
            isDisabled={props.isDisabled || false}
            placeholder={placeholder}
            onBlur={onBlur}
        />
    );
}

export default DebouncedTextInput;
