import React, { useEffect } from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import '@patternfly/react-core/dist/styles/base.css';

import './App.css';

import Layout from './Layout';
import routes, { ROUTE_PATH_CONTOUR, ROUTE_PATH_DOSE } from './routes';

import CustomizationPage from './pages/customization/CustomizationPage';
import GlobalRoisPage from './pages/global-rois/GlobalRoisPage';
import TextEditorPage from './pages/text-editor/TextEditorPage';
import LicensePage from './pages/license/LicensePage';
import AboutPage from './pages/about/AboutPage';
import LoggedOutPage from './pages/logged-out/LoggedOutPage';
import { LOGGED_OUT_URI } from './environments';
import AppWrapper from './pages/auth/AppWrapper';
import HomePage from './pages/home/HomePage';
import DaemonConfigPage from './pages/daemon/DaemonConfigPage';
import ContourModelSelectionPage from './pages/selection/contour/ContourModelSelectionPage';
import DoseModelSelectionPage from './pages/selection/dose/DoseModelSelectionPage';
import { appConfigSelectors } from './store/appConfig/appConfigSlice';
import { contouringSelectors } from './store/contouring/contouringSlice';
import { ModelType } from './store/global-types/customization-types';
import LogInPage from './pages/auth/LogInPage';
import SupportedModelTypePageWrapper from './pages/auth/SupportedModelTypePageWrapper';
import { SupportedModelType } from './store/appConfig/appDeploymentInfoTypes';

const loader = document.querySelector('.loader');
const hideLoader = () => loader?.classList.add('loader-hide');

function App() {

  // hide the splash screen once the app has loaded
  useEffect(hideLoader, []);

  /** Prompt user if they're trying to close the app with unsaved changes. */
  const anyUnsavedChanges = useSelector(contouringSelectors.selectAnyUnsavedContouringChangesInApp);
  useEffect(() => {
    if (anyUnsavedChanges) {
      window.onbeforeunload = function () {
        // Note that modern browsers don't actually support showing these messages, but returning 
        // a string here makes them show their default "you have unsaved changes" dialog.
        return "You have unsaved changes. Are you sure you want to leave?";
      };
    } else {
      // remove any beforeunload prompt
      window.onbeforeunload = null;
    }
  }, [anyUnsavedChanges]);

  const deploymentInfo = useSelector(appConfigSelectors.selectAppDeploymentInfo);

  return (
    <Router>
      <Switch>
        <Route path={LOGGED_OUT_URI}>
          <LoggedOutPage />
        </Route>

        <Route exact path={routes.loginPage.slug}>
          <AppWrapper>
            <LogInPage />
          </AppWrapper>
        </Route>

        <Route path={'/'}>
          <AppWrapper>
            <Layout>
              <Switch>

                <Route path={ROUTE_PATH_CONTOUR}>
                  <SupportedModelTypePageWrapper supportedModelType={SupportedModelType.Contour}>
                    <Route path={routes.contourCustomizationPage.slug}>
                      <CustomizationPage modelType={ModelType.Contouring} />
                    </Route>
                    <Route path={routes.contourAllStructuresPage.slug}>
                      <GlobalRoisPage />
                    </Route>
                    <Route path={routes.contourModelSelectionPage.slug}>
                      <ContourModelSelectionPage />
                    </Route>
                  </SupportedModelTypePageWrapper>
                </Route>

                <Route path={ROUTE_PATH_DOSE}>
                  <SupportedModelTypePageWrapper supportedModelType={SupportedModelType.Dose}>
                    <Route path={routes.doseCustomizationPage.slug}>
                      <CustomizationPage modelType={ModelType.Dose} />
                    </Route>
                    {/* <Route path={routes.doseModelSelectionPage.slug}>
                    <DoseModelSelectionPage />
                  </Route> */}
                  </SupportedModelTypePageWrapper>
                </Route>

                {deploymentInfo && !deploymentInfo.disableDaemonConfigPage && (
                  <Route path={routes.daemonConfigPage.slug}>
                    <DaemonConfigPage />
                  </Route>
                )}
                
                <Route path={routes.textEditorPage.slug}>
                  <TextEditorPage />
                </Route>
                <Route path={routes.licensePage.slug}>
                  <LicensePage />
                </Route>
                <Route path={routes.aboutPage.slug}>
                  <AboutPage />
                </Route>
                <Route path={routes.homePage.slug}>
                  <HomePage />
                </Route>

              </Switch>
            </Layout>
          </AppWrapper>
        </Route>
      </Switch>
    </Router >
  );
}

export default App;
