import React from 'react';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../store/store';
import { doseSelectors } from '../../../store/dose/doseSlice';
import DoseRoiItem from './DoseRoiItem';

interface DoseRoiFormProps {
    roiId: string;
    roiIdList: string[];
}

const DoseRoiForm = (props: DoseRoiFormProps) => {

    const { roiId, roiIdList } = props;

    const roi = useSelector((state: StoreState) => doseSelectors.selectRoiById(state, roiId));

    if (roi === undefined) {
        return null;
    }

    return (
        <DoseRoiItem
            roiId={roiId}
            rowActions={undefined}
        />
    );
}

export default DoseRoiForm;
