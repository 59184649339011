import { ExpandableSection, Button, Alert, Modal } from "@patternfly/react-core";
import { t } from "i18next";
import React, { useCallback, useEffect } from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";

import { configurationTargetSelectors } from "../../store/configurationTarget/configurationTargetSlice";
import { getUserName, MVisionAppClient } from "../../store/configurationTarget/mvision-client-list";

interface ConfigurationResetProps {
    modelTypeUiString: string,
    isResetInProgress: boolean,
    onResetAllModelCustomizations: (configurationTarget: MVisionAppClient) => void,
}

const ConfigurationReset = (props: ConfigurationResetProps) => {
    const { modelTypeUiString, isResetInProgress, onResetAllModelCustomizations } = props;

    const currentTarget = useSelector(configurationTargetSelectors.selectCurrent);

    const [isResetBoxExpanded, setIsResetBoxExpanded] = React.useState(false);
    const [isResetConfirmationModalOpen, setIsResetConfirmationModalOpen] = React.useState(false);
    const [isResetCompletedTextVisible, setIsResetCompletedTextVisible] = React.useState(false);
    const [isResetting, setIsResetting] = React.useState(false);


    const handleExpandResetBoxClicked = useCallback((_: unknown, isOpen: boolean) => {
        setIsResetBoxExpanded(isOpen);
    }, []);

    const handleResetButtonClicked = useCallback(() => {
        setIsResetConfirmationModalOpen(true);
    }, []);

    const handleCloseResetModalClicked = useCallback(() => {
        setIsResetConfirmationModalOpen(false);
    }, []);

    const handleConfirmResetClicked = useCallback(() => {
        if (!isResetInProgress) {
            if (!currentTarget) {
                throw new Error('No configuration target selected, cannot reset');
            }
            onResetAllModelCustomizations(currentTarget);
        }
        setIsResetConfirmationModalOpen(false);
        setIsResetting(true);
        setIsResetCompletedTextVisible(false);
    }, [currentTarget, isResetInProgress]);

    useEffect(() => {
        if (isResetting && !isResetInProgress) {
            // show reset completed text once it's done
            setIsResetCompletedTextVisible(true);
        }
    }, [isResetInProgress, isResetting]);

    return (
        <>
            <ExpandableSection className="mv-warning" toggleContent={(<b>{t('settings.resetConfiguration.title')}</b>)} onToggle={handleExpandResetBoxClicked} isExpanded={isResetBoxExpanded} displaySize="lg" isWidthLimited>
                <div className="setting-item">
                    <span className="label">{t('settings.resetConfiguration.text')}</span>
                    <span className="value">
                        <Button variant="danger" onClick={handleResetButtonClicked} isDisabled={isResetInProgress} isLoading={isResetInProgress}>{t('settings.resetConfiguration.confirm.button')}</Button>
                    </span>
                </div>

                {isResetCompletedTextVisible && (
                    <div className="configuration-reset-completed-box">
                        <Alert variant="info" isInline title={t('settings.resetConfiguration.success')} />
                    </div>
                )}

                <div>
                    <Trans i18nKey="settings.resetConfiguration.warning">
                        Resetting <span className="configuration-target-model-type">{{ modelType: modelTypeUiString }}</span> model configuration to factory settings will remove all current model customizations and model selections, including any unsaved changes, and return them back to default values. This operation <b>cannot be reverted.</b>
                    </Trans>
                </div>
            </ExpandableSection>

            <Modal
                isOpen={isResetConfirmationModalOpen}
                onClose={handleCloseResetModalClicked}
                variant={"medium"}
                title={t('settings.resetConfiguration.confirm.title')}
                actions={[
                    <Button key="confirm-reset" onClick={handleConfirmResetClicked} variant="danger">{t('settings.resetConfiguration')}</Button>,
                    <Button key="cancel-reset" onClick={handleCloseResetModalClicked} variant="tertiary">{t('common.cancel')}</Button>
                ]}
                className="reset-confirmation-modal">
                <div>
                    <Trans i18nKey="settings.resetConfiguration.confirm.caution1">
                        Reset <span className="configuration-target-model-type">{{ modelType: modelTypeUiString }}</span> configuration settings for configuration target <span className="reset-emphasis configuration-target-name">{{ configurationTargetName: currentTarget ? getUserName(currentTarget) : '' }}</span> back to factory defaults?
                    </Trans>
                </div>
                <div>
                    <Trans i18nKey="settings.resetConfiguration.confirm.caution2">
                        Resetting model configuration to factory settings will remove all current model customizations and model selections, including any unsaved changes, and return them back to default values. This operation <span className="reset-emphasis">cannot be reverted.</span>
                    </Trans>
                </div>
            </Modal>

        </>
    );
}

export default ConfigurationReset;
