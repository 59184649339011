import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ModelSelectionUndoState } from '../ModelSelectionPage';
import { StoreState } from '../../../store/store';
import { aeTitleRuleActionUpdated, aeTitleRuleRemoved, contouringSelectors } from '../../../store/contouring/contouringSlice';
import { ModelType } from '../../../store/global-types/customization-types';
import AeTitleRuleForm from '../AeTitleRuleForm';



interface ContourAeTitleRuleProps {
    aeTitleRuleId: string,
    runningNumber: number,
    setUndoState: (undoState: ModelSelectionUndoState) => void,
    isUndoDisabled?: boolean,
}

const ContourAeTitleRule = (props: ContourAeTitleRuleProps) => {
    const { aeTitleRuleId, runningNumber, setUndoState, isUndoDisabled } = props;

    const dispatch = useDispatch();

    const aeTitleRule = useSelector((state: StoreState) => contouringSelectors.selectAeTitleRuleById(state, aeTitleRuleId));
    const validationError = useSelector((state: StoreState) => contouringSelectors.selectCustomizationValidationError(state, aeTitleRuleId));

    const handleAeTitleActionChanged = useCallback((action: string) => {
        dispatch(aeTitleRuleActionUpdated({ id: aeTitleRuleId, action }));
    }, [dispatch, aeTitleRuleId]);


    const handleAeTitleRemoved = useCallback(() => {
        dispatch(aeTitleRuleRemoved(aeTitleRuleId));
    }, [dispatch, aeTitleRuleId]);


    // don't render anything if we don't have a valid object
    if (aeTitleRule === undefined) {
        return null;
    }

    return (
        <AeTitleRuleForm
            aeTitleRule={aeTitleRule}
            modelType={ModelType.Contouring}
            onAeTitleRuleActionUpdated={handleAeTitleActionChanged}
            onAeTitleRuleRemoved={handleAeTitleRemoved}
            runningNumber={runningNumber}
            setUndoState={setUndoState}
            validationError={validationError}
            isUndoDisabled={isUndoDisabled}
         />
    );
}

export default ContourAeTitleRule;
