import {
    DropdownItem,
	FormGroup,
    MenuToggle,
    MenuToggleElement,
    Select,
    SelectOption
} from '@patternfly/react-core';
import React, { memo, useCallback } from 'react';

interface InterpretedTypeDropdownProps {
    onSelect: (interpretedType: string) => void,
    value: string,
}

const supportedInterpretedTypes = [
    'ORGAN',
    'EXTERNAL',
    'PTV',
    'CTV',
    'GTV',
    'CONTROL',
    'BOLUS',
    'AVOIDANCE',
    'MARKER',
    'CAVITY',
    'SUPPORT',
    'FIXATION',
    'REGISTRATION',
    'ISOCENTER',
    'CONTRAST_AGENT',
    'TREATED_VOLUME',
    'IRRAD_VOLUME',
    'BRACHY_CHANNEL',
    'BRACHY_ACCESSORY',
    'BRACHY_SRC_APP',
    'BRACHY_CHNL_SHLD',
    'DOSE_REGION',
    'DOSE_MEASUREMENT',
];

const dropdownItems = supportedInterpretedTypes.map(i => (<SelectOption key={i}>{i}</SelectOption>));

const InterpretedTypeDropdown = (props: InterpretedTypeDropdownProps) => {
    const { value, onSelect } = props;

    const [isOpen, setIsOpen] = React.useState(false);

    const handleToggleInterpretedType = useCallback(() => {
        setIsOpen(!isOpen);
    }, []);

    // const onFocus = useCallback(() => {
    //     const element = document.getElementById('toggle-basic');
    //     element.focus();
    // }, []);

    const handleSelect = useCallback((value: any) => {
        setIsOpen(false);
        // onFocus();
        onSelect(value.target.innerText)
    }, []);

    return (
        <FormGroup className="interpreted-type">
            <Select
                toggle={(toggleRef: React.Ref<MenuToggleElement>) => (
                    <MenuToggle ref={toggleRef} onClick={handleToggleInterpretedType} isFullWidth>
                        {value}
                    </MenuToggle>
                )}
                
                isOpen={isOpen}
                onOpenChange={() => setIsOpen(!isOpen)}
                popperProps={{position: 'right'}}
                onSelect={handleSelect}
            >{dropdownItems}</Select>
        </FormGroup>
    );
}

export default memo(InterpretedTypeDropdown);
