import { has, isNumber } from "lodash-es";
import { AeTitleRule, CustomizationBase, CustomizationOutput, DicomAttributeRule, DicomRule, DoseUnit, isCustomizationOutput, Model, OutputMetadataItem } from "../global-types/customization-types";

export enum TargetMethod { NotSet = 'not_set', Variable = 'variable', Fixed = 'fixed' };

export enum DoseScalingMethod { NotSet = 'not_set', Volume = 'volume', Mean = 'mean' };

export type PixelSpacing = undefined | [number] | [number, number] | [number, number, number];

export const EXPORT_DOSE_JSON_FILE_PREFIX = 'mvision-dose-';

/** A single customization output (ie. a result file) for a dose model. A customization base contains one or more of these. */
export type DoseCustomizationOutput = CustomizationOutput & {

    /** List of OARs used during dose prediction. */
    rois: string[]; // DoseRoi ids

    /** List of targets (i.e. PTV) used during dose prediction. */
    targets: string[]; // DoseTarget ids

    /** The method to scale the predicted dose map values. */
    doseScaling: DoseScaling;

    /** Pixel spacing in mm. (TODO: are all of these types necessary?) */
    pixelSpacing: PixelSpacing;

    /** The method used to crop the dose in the Z-direction. */
    zDoseCropping: ZDoseCropping;

    /** Whether to return the RTPLAN or not. */
    isRTPlanIncluded: boolean;

    /** Machine type used in the RTPLAN file. */
    machineType: string;

    /** Machine name used in the RTPLAN file. */
    machineName: string;
};

/** Customization settings for a single dose ROI. */
export type DoseRoi = {
    /** Redux ID of this dose ROI. */
    id: string;

    /** ROI name. */
    name: string;
    /** Regular expression used to find this ROI. */
    regExp: string;
    /** True: ROI will be used for prediction. False: ROI will not be used for prediction. NOTE: this cannot currently be adjusted in UI. */
    isIncluded: boolean;
    /** This ROI must be found for the dose prediction to work. If this ROI is not found, then the model may not process the task or produce bad quality dose prediction. */
    isMandatory: boolean;

    /** True if this roi customization has unsaved changes, false otherwise. */
    isModified: boolean;
    // /** If true, scroll the viewport to show this item when it's mounted into DOM. This is used to auto-scroll to newly added items. */
    // scrollToView: boolean;

    /** Parent model customization output this roi customization belongs to. */
    outputId?: string;  // this is optional because it's usually set post-constructor
    // /** Parent global roi customization this roi customization belongs to, if any. */
    // globalRoiId?: string | undefined;  // this is optional because it's usually set post-constructor
};

/** A target (i.e. PTV) used during dose prediction. */
export type DoseTarget = {
    /** Redux ID of this dose target. */
    id: string;

    /** Method of finding the target. Either "variable" for getting the prescription dose from the ROI name,
     *  or "fixed" for a fixed dose if the regular expression is matched. */
    method: TargetMethod;

    /** Unit of radiation used in this dose target. */
    unit: DoseUnit;

    /** Regular expression to find target ROIs, optionally including a method to extract the PTV prescription. */
    regExp: string;

    prescription: number | null;

    isModified: boolean;

    outputId?: string;
};

/** The method to scale the predicted dose map values. */
export type DoseScaling = {
    /** Boolean to flag whether this method will be used or not. */
    isActive: boolean;

    /** Dose scaling method. "volume" is volume attribute is used, "mean" otherwise  */
    method: DoseScalingMethod;

    /** Relative volume of the maximum PTV that needs to have a certain minimum dose. */
    volume: number;

    /** Dose that the maximum PTV volume needs to have. */
    dose: number;
};

/** The method used to crop the dose in the Z-direction. */
export type ZDoseCropping = {
    /** Boolean to flag whether this method will be used or not. */
    isActive: boolean;

    /** Number of cm away from the ROI-union that will be cropped away. */
    value: number;
};

/** A full collection of dose customization-related entities and any
 * related internal helper objects. */
export type DoseCustomizationEntities = {
    models: Model[];
    customizationBases: CustomizationBase[];
    doseOutputs: DoseCustomizationOutput[];
    outputMetadata: OutputMetadataItem[];
    doseRois: DoseRoi[];
    doseTargets: DoseTarget[];
    // globalRoiCustomizations: GlobalContouringRoi[];
    aeTitleRules: AeTitleRule[];
    dicomRules: DicomRule[];
    dicomAttributeRules: DicomAttributeRule[];
};

/** A full collection of dose customization-related entities without
 * any internal helper entities that are not needed for export.
 * NOTE: as of now this is identical with DoseCustomizationEntities but
 * it's possible these will diverge in future. */
export type DoseCustomizationEntitiesForExport = {
    models: Model[];
    customizationBases: CustomizationBase[];
    doseOutputs: DoseCustomizationOutput[];
    outputMetadata: OutputMetadataItem[];
    doseRois: DoseRoi[];
    doseTargets: DoseTarget[];
    aeTitleRules: AeTitleRule[];
    dicomRules: DicomRule[];
    dicomAttributeRules: DicomAttributeRule[];
};

/** View model entities related to dose output */
export type DoseCustomizationOutputEntities = {
    outputs: DoseCustomizationOutput[];
    metadata: OutputMetadataItem[];
    rois: DoseRoi[];
    targets: DoseTarget[];
};


// *** type guards ***

export const isDoseCustomizationOutput = (obj: any): obj is DoseCustomizationOutput => {
    const output = obj as DoseCustomizationOutput;
    return isCustomizationOutput(obj)
        && output.rois !== undefined
        && output.targets !== undefined;
}

export const isDoseRoi = (obj: any): obj is DoseRoi => {
    const roi = obj as DoseRoi;
    return roi.regExp !== undefined
    // must not look like ContouringRoi or GlobalContouringRoi
    && !has(roi, 'operation')
    && !has(roi, 'color')
    && !has(roi, 'coveredRois');
}

export const isDoseTarget = (obj: any): obj is DoseTarget => {
    const target = obj as DoseTarget;
    return target.method !== undefined && target.regExp !== undefined && (target.prescription === null || isNumber(target.prescription));
}
